.remove-under-1440 {
  display: unset;
}

.remove-under-1250 {
  display: unset;
}

.show-above-1250 {
  display: none;
}

.show-above-1440 {
  display: none;
}

@media (max-width: 1720px) {
  .tableSearch .search-input-table {
    transition: linear .4s;
    z-index: 99999999;
    width: 45px;
  }

  .tableSearch .search-input-table:hover {
    width: 300px;
    z-index: 99999999;
    transition: linear .4s;
  }

  .menuFilterView {
    margin-right: 75px !important;
  }


  .table-page-container {
    padding-top: 80px !important;
  }

  .menuFilterView {

    margin-bottom: -75px !important;
    margin-top: 80px !important;

  }

  .table-page-container .form-header {
    position: absolute;
    top: -40px;
    left: 35px;
  }

  .menuFilterView{
    width:  calc(100% - 105px) !important;

  }
}

@media (max-width: 1439px) {
  .show-above-1440 {
    display: unset;
  }

  .remove-under-1440 {
    display: none !important;
  }

  .input-with-icon input {
    font-size: 12.9px;
  }

  .input-with-icon img {
    width: 20px;
    height: 20px;
  }

  .forgot-password-under-1440 {
    color: #8697a8;
  }

  .forget-password-link {
    font-size: 11.1px;
  }

  .login-button button {
    font-weight: 500;
    padding-top: 14px;
    padding-bottom: 14px;
    font-size: 12.9px;
    box-shadow: 0 20px 20px 0 rgba(0, 129, 255, 0.2),
      0 -5px 15px 0 rgba(0, 129, 255, 0.15);
  }

  .login-button {
    margin-top: 23px;
    margin-bottom: 29px;
  }

  .login-form-inputs-margin {
    margin-top: 40px;
  }

  .forgot-password-top-text {
    text-align: left;
  }

  .forgot-password-main {
    padding-left: 30px;
    padding-right: 30px;
    box-shadow: unset;
  }

  .forgot-password-main-bottom {
    padding: unset;
    background-color: unset;
  }

  .forgot-password-main-bottom .input-with-icon {
    background-color: #f8fafb;
  }

  .forget-password-container {
    background-color: #ffffff;
  }

  .forgot-password-top-text {
    margin-bottom: 30px;
    font-size: 25.7px;
    font-weight: bold;
  }

  .forgot-button {
    margin-top: 14px;
  }

  .account-image-section {
    width: 176px;
    height: 176px;
  }

  .card-white-section small {
    font-size: 24px;
  }

  .account-balance small {
    font-size: 13px;
  }

  .account-balance {
    width: 145px;
  }

  .card-white-section {
    padding-left: 5px;
    padding-right: 5px;
  }

  .card-gray-section {
    padding-right: 5px;
    padding-left: 10%;
  }

  .sub-card-containers {
    padding-right: 10px;
    padding-left: 10px;
  }

  .form-information-section-header small {
    font-size: 18px;
  }

  .form-information-section small {
    font-size: 12px;
  }

  .card-gray-section-p30 {
    padding-left: 5px;
    padding-right: 5px;
  }

  .card-main-white-section {
    padding-left: 5px;
    padding-right: 5px;
  }

  .payment-card-image {
    width: 125px;
  }

  .payment-card-detail {
    padding-left: 5px;
    padding-right: 5px;
  }

  .payment-card-detail small {
    font-size: 13px;
  }

  .visa-card-section img {
    width: 230px;
    height: 165px;
  }

  .section-containers-header small.header {
    font-size: 18px;
  }

  .section-containers-header button {
    font-size: 13px;
    margin-left: 5px;
  }

  .section-containers-header small {
    font-size: 12px;
  }

  .main-container-contact-form {
    padding: 10px;
  }

  .main-container-contact-form .input-with-icon {
    margin-right: 0px;
  }

  .from-button-container {
    padding-right: 0px;
  }
}

@media (max-width: 1249px) {
  .remove-under-1250 {
    display: none !important;
  }

  .show-above-1250 {
    display: unset;
  }

  .input-with-label input {
    padding-left: 11px;
  }

  .input-with-label {
    margin-top: 5px;
  }

  .modal-options .side-items-first-element small {
    margin-left: 5px;
    font-size: 12px;
  }

  .modal-options .side-items-first-element {
    padding: 3px;
  }

  .input-with-label input {
    min-width: unset;
  }

  .modal-account-account-detail {
    flex-direction: column;
  }

  .modal-account-account-detail .right-section {
    margin-left: 0px !important;
  }

  .account-modal-container {
    flex-direction: column;
  }

  .modal {
    overflow: scroll;
  }

  .modal-options {
    display: none !important;
    margin-top: unset;
    flex-direction: row !important;
  }

  .account-modal-left-side {
    min-width: 245px !important;
    padding: 0px 0px 0px 0px;
    min-width: unset;
    height: unset;
    background-color: #f8fafb;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .account-image-section img {
    width: 40px;
    height: 30px;
  }

  .account-image-section {
    width: 100px;
    height: 100px;
    margin-left: 10px !important;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .custom-file-upload img {
    width: 30px;
    height: 30px;
  }

  .custom-file-upload small {
    color: white;
    font-family: Roboto;
    font-size: 9px !important;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
  }

  .account-modal-shipping {
    padding: 15px;
  }

  .modal-content-section small.header {
    font-family: Roboto;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #44566c;
    margin-bottom: 20px;
  }

  .input-with-label-container {
    margin-top: 5px;
  }

  .input-with-label-container small {
    font-size: 12px;
  }

  .input-with-label input {
    font-size: 13px;
  }

  .front-footer-container {
    flex-direction: column;
    padding: 60px 70px 30px 70px;
  }

  .front-page-link-active {
    font-size: 15px;
    margin-bottom: 5px;
  }

  .front-page-link-deactive {
    font-size: 15px;
    margin-bottom: 5px;
  }

  .front-footer-section-container .front-header-contents {
    width: 100%;
    justify-content: space-around;
    margin-top: 10px;
  }

  .front-header-buttons button {
    font-size: 13px;
  }

  .footer-information-bottom small {
    font-size: 12px;
  }

  .front-logo-footer h3 {
    text-align: center;
    font-size: 13px;
  }

  .footer-page-link-active {
    font-size: 12px;
    margin-right: 0px;
    border-right: unset !important;
  }

  .footer-page-link-deactive {
    font-size: 12px;
    margin-right: 0px;
    border-right: unset !important;
  }

  .front-header-container {
    padding: 0px 5px 0px 5px;
    justify-content: space-between;
  }

  .front-logo-header img {
    width: 84px;
  }

  .front-main-container-overlay h1 {
    font-size: 28px;
  }

  .front-main-container-overlay h2 {
    font-size: 17px;
  }

  .front-landing-from {
    margin-top: 65px;
  }

  .front-main-container-overlay {
    padding: 60px 15px 0px 15px;
  }

  .front-landing-information-section {
    flex-direction: column;
    height: unset;
    padding: 10px;
    margin-top: 230px;
    text-align: center;
  }

  .front-landing-information-section div {
    padding: unset;
    width: 100%;
  }

  .front-landing-information-section small {
    font-size: 18px;
  }

  .front-landing-information-section p {
    font-size: 14px;
  }

  .front-landing-information-section-individual {
    flex-direction: column;
  }

  .front-landing-information-section-individual small {
    font-size: 18px;
  }

  .front-landing-information-section-individual div {
    width: unset;
    height: unset;
    padding: 10px;
    margin-bottom: 10px;
  }

  .front-landing-information-section-individual p {
    font-size: 14px;
  }

  .platoon-ready-section {
    padding: 15px;
    justify-content: space-around;
  }

  .platoon-ready-section h2 {
    font-size: 28px;
    text-align: center;
  }

  .platoon-ready-section h3 {
    font-size: 14px;
    text-align: center;
  }

  .platoon-ready-section {
    flex-direction: column;
  }

  .front-landing-information-section div.line {
    width: 50%;
    height: 1px;
    margin: 24px 0;
    border-bottom: solid 1px #979797;
    border-left: none;
    padding: 0;
  }

  .front-landing-from {
    flex-direction: column;
  }

  .front-landing-from .input-with-icon {
    margin-bottom: 10px;
    margin-right: 0px;
  }

  .front-sub-page-items a {
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: 14px;
  }

  .front-sub-page-items a:hover {
    border-bottom: 1px solid white;
  }

  .front-sub-page-items a.active {
    border-bottom: 1px solid white;
  }

  .front-overal-paper {
    padding: 15px;
  }

  .front-overal-paper h6,
  .front-overal-paper p,
  .front-overal-paper h3 {
    font-size: 14px;
  }

  .front-sub-page-items {
    flex-wrap: wrap;
  }

  .front-overal-paper-contact {
    padding: 15px;
  }

  .front-overal-paper-contact .content .information {
    padding: 0px;
  }
}

@media (max-width: 576px) {
  .page-container {
    padding: 15px 10px 15px 10px;
  }

  .front-overal-paper-contact .content .information .options a {
    font-size: 10px;
    height: 45px;
  }

  .main-container-contact-form .input-with-icon {
    min-width: 200px;
  }

  .header-container {
    padding: 10px;
  }

  .header-drop-menu {
    right: 9px;
    top: 69px;
  }
}

@media (max-width: 992px) {
  .navbar-nav .nav-item {
    padding: 10px;
  }
}

@media (min-width: 1200px) {
  .login-form-section {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .front-footer-section-container {
    flex-direction: column;
  }
}