.account-modal-container {
  display: flex;
}

.account-modal-left-side {
  min-width: 300px;
  height: 675px;
  padding: 30px 0px 30px 0px;
  background-color: #f8fafb;
}

.account-modal-close {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 28px;
  height: 28px;
  border-radius: 14px;
}

.account-modal-close button {
  padding: 0px;
  min-width: unset;
  text-transform: none;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  margin-top: -2px;
}

.account-modal-close img {
  width: 22px;
  height: 22px;
}

.account-modal-shipping {
  position: relative;
  padding: 30px 40px 30px 40px;
  background-color: #ffffff;
}

.modal-options {
  margin-top: 72px;
}

.modal-content-section small.header {
  font-family: Roboto;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #44566c;
  margin-bottom: 20px;
}

.modal-dialog {
  max-width: unset !important;
}
