*:focus {
  outline: none;
}

.front-landing-from .input-with-icon {
  width: 250px;
  height: 50px;
  margin-right: 32px;
  margin-bottom: 0px;
}

.front-landing-from .input-with-icon.input-with-icon img {
  top: 18px;
}

.front-overal-paper {
  width: 100%;
  background-color: white;
  padding: 40px 57px 40px 57px;
  border-radius: 10px;
  box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.3);
}

.document strong {
  color: #000;
}

.document ul li ul {
  margin-left: 20px;
}

.last-modified-header {
  margin-bottom: 36px;
}

.last-modified-header img {
  width: 26px;
  height: 26px;
}

.last-modified-header small {
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #737781 !important;
  margin-left: 5px;
  margin-top: 2px;
}

.front-overal-paper h2 {
  font-family: Roboto;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #44566c;
  text-align: left;
  height: unset !important;
  margin-bottom: 20px;
}

.front-overal-paper h6 {
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  text-align: left;
  font-style: normal;
  line-height: 1.63;
  margin-bottom: 0px;
  letter-spacing: normal;
  color: #737781bd;
}

.front-overal-paper h3 {
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  text-align: left;
  font-style: normal;
  line-height: 1.63;
  margin-bottom: 0px;
  letter-spacing: normal;
  color: #686c75;
}

.front-overal-paper p {
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  text-align: left;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: #737781bd;
  margin-bottom: 30px;
}

.front-sub-page-items {
  padding-top: 25px;
  margin-bottom: 30px;
}

.front-sub-page-items a {
  color: #b3c0ce;
  border: none;
  outline: none;
  background-color: transparent;
  margin-right: 40px;
  font-family: Roboto;
  font-size: 22px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  cursor: pointer;
  padding-bottom: 10px;
  transition: 0.7s color, 0.7s border-bottom-color;
}

.dropDownInput {
  position: absolute;
  background: red;
  width: 100%;
  left: 0;
  top: 0;
  display: block;
}

.tagsView {
  overflow-y: visible !important;
  max-height: 350px;
}

.langFlag {
  position: relative !important;
  left: auto !important;
  top: auto !important;
  width: 17px;
  margin-right: 5px;
}

.front-sub-page-items a.active {
  padding-bottom: 8px;
  color: white;
  border-bottom: 2px solid white;
}

.front-sub-page-items a:hover {
  padding-bottom: 8px;
  color: white;
  border-bottom: 2px solid white;
}

.front-overal-paper-contact {
  width: 100%;
  background-color: white;
  padding: 40px 65px 40px 65px;
  border-radius: 10px;
  box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.3);
  margin-bottom: 110px;
}

.front-overal-paper-contact h3 {
  color: #44566c;
  font-family: Roboto;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-bottom: 34px;
}

.front-overal-paper-contact .content .help-links {
  width: 300px;
  padding: 15px 0px 60px 40px;
  border-left: 2px solid #73778182;
  max-height: 240px;
  display: flex;
}

.front-overal-paper-contact .content .help-links a {
  color: #737781 !important;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-bottom: 10px;
}

.front-overal-paper-contact .content .help-links small {
  color: #44566c !important;
  font-family: Roboto;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-bottom: 20px;
}

.front-overal-paper-contact .content .information {
  width: 100%;
  padding: 0px 45px 0px 0px;
}

.front-overal-paper-contact .content .information .options {
  width: 100%;
  z-index: 1;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.front-overal-paper-contact .content .information .options a {
  width: 33%;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  font-family: Roboto;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #e5e5e5;
  cursor: pointer;
  transition: 0.6s all;
}

.front-overal-paper-contact .content .information .options a:hover {
  background-color: #00b7ff;
}

.front-overal-paper-contact .content .information .options a.active {
  background-image: linear-gradient(to right, #0081ff, #00b7ff);
}

.h100vh {
  height: 100vh;
}

.main-container-contact-form {
  display: flex;
  border-radius: 10px;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 75px 45px 45px 45px;
  background-color: white;
  box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.3);
  z-index: 2;
  margin-top: -5px;
  position: relative;
}

.sortByItem img {
  height: 20px;
  width: 30px;
}

.main-container-contact-form .input-with-icon {
  height: 50px;
  min-width: 300px;
  flex: 1;
  margin-right: 42px;
  margin-bottom: 20px;
}

.main-container-contact-form .input-with-icon img {
  top: 14px;
  width: 20px;
  height: 20px;
}

.form-button {
  margin-top: 20px;
  width: 200px;
  height: 50px;
}

.form-button button {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  background-color: #0081ff;
  border-radius: 5px;
  box-shadow: 0 30px 20px 0 rgba(0, 129, 255, 0.2),
    0 -5px 15px 0 rgba(0, 129, 255, 0.15);
  width: 100%;
  padding-top: 11px;
  padding-bottom: 11px;
  border-color: transparent;
  outline: none;
  transition: 0.4s all;
  cursor: pointer;
}

.form-button button:hover {
  border-radius: 5px;
  box-shadow: unset;
}

.from-button-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 41px;
}

.contact-from-number-dp {
  display: flex;
}

.contact-from-number-dp .input-with-icon {
  min-width: 274px;
}

.contact-from-number-dp .drop-down-menu {
  margin-right: 10px;
  width: 125px;
  min-width: unset !important;
}

.contact-from-number-dp .drop-down-menu small {
  left: 5px;
}

/* Dashbord Style */

.layout div.item {
  background: #fff;
  box-shadow: 0 0px 16px 0 rgba(169, 194, 209, 1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}

.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
  z-index: 99;
}

.react-grid-item.cssTransforms {
  transition-property: transform;
}

.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.react-grid-placeholder {
  background: #9b9b9b;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-grid-item>.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 3px;
  right: 3px;
  background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgNiA2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjZweCIgaGVpZ2h0PSI2cHgiDT4NCTxnIG9wYWNpdHk9IjAuMzAyIj4NCQk8cGF0aCBkPSJNIDYgNiBMIDAgNiBMIDAgNC4yIEwgNCA0LjIgTCA0LjIgNC4yIEwgNC4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iIzAwMDAwMCIvPg0JPC9nPg08L3N2Zz4=");
  background-position: bottom right;
  padding: 0 3px 3px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: se-resize;
}

#content {
  width: 100%;
}

.react-grid-layout {}

.layoutJSON {
  background: #ffffff;
  border: 1px solid black;
  margin-top: 10px;
  padding: 10px;
}

.columns {
  -moz-columns: 120px;
  -webkit-columns: 120px;
  columns: 120px;
}

.react-grid-item {
  box-sizing: border-box;
}

.react-grid-item:not(.react-grid-placeholder) {
  /*
                background: #fff;
                border-radius: 5px;
                box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.1);
                */
}

.react-grid-item.resizing {
  opacity: 0.9;
}

.react-grid-item.static {}

.react-grid-item .text {
  font-size: 24px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 24px;
}

.react-grid-item .minMax {
  font-size: 12px;
}

.react-grid-item .add {
  cursor: pointer;
}

.react-grid-dragHandleExample {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.itemRow {
  width: 100%;
  height: 100%;
  padding: 10px;
  /*overflow: hidden;*/
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.1);
}

.leaflet-container {
  width: 100%;
  height: 100%;
}

.titleItemTable {
  /* position: absolute; */
}

.CardSelect {
  font-size: 14px;
  padding: 3px;
}

.tdSetting {
  width: 25px;
  height: 25px;
  cursor: pointer;
  display: flex;
  margin-left: 5px;
  /* background: url("/assets/dots.svg") center; */
}

.mapViewFa {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: 100%;
  background: #fff;
  justify-content: center;
  align-items: center;
}

.PieChartInfo {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.PieChartDate {
  /* position: absolute;
  left: 0px;
  top: 0px; */
  font-size: 15px;
  color: #44566c;
  margin-bottom: 20px;
}

i.AVAILABLE {
  background: #09b66d;
}

i.DRIVE {
  background: #0081ff;
}

i.WORK {
  background: #fdbf5e;
}

i.REST {
  background: #ff8a48;
}

i.Unknown {
  background: #ff3d57;
}

i.HOLIDAY {
  background: #44566c;
}

.mapViewDataDiv {
  width: 100%;
  text-align: left;
  font-size: 12px;
  margin-bottom: 10px;
  float: left;
}

.mapViewDataDiv strong {
  width: 50%;
  float: left;
  font-weight: normal;
}

.mapViewDataDiv span {
  width: 50%;
  display: block;
  height: 40px;
  color: #8697a8;
  float: left;
  text-align: left;
  font-size: 15px;
}

.mapZoomOutCustom {
  width: 30px;
  height: 30px;
  background: #fff;
  position: absolute;
  z-index: 999999999;
  top: 120px;
  right: 10px;
  border-radius: 5px;
  box-shadow: 0 0 0 2px rgb(0 0 0 / 10%);
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
}

.mapZoomOutCustom img {
  width: 80%;
}

.mapboxgl-popup-close-button {
  position: absolute;
  right: 0px;
  width: 15px;
  height: 15px;
  top: 0px;
  border: 0;
  border-radius: 10px;
  cursor: pointer;
}




.customSelect {
  height: 40px;
  padding: 5px 15px;
  background: #fff;
  border-radius: 5px;
  position: relative;
  margin-left: 10px;
  box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.1);
  cursor: pointer;
}

.customSelectValue {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #44566c;
}

.customSelectValue img {
  margin-left: 5px;
}

.customSelectOptionsView {
  display: none;
  position: absolute;
  width: 140%;
  height: auto;
  background: #fff;
  left: 0;
  box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.1);
  border-radius: 5px;
  top: 45px;
  padding: 5px;
}

.customSelectOptionsViewShow {
  display: block;
}

.customSelectOptionsView div {
  color: #44566c;
  text-align: center;
  padding: 2px 0;
  cursor: pointer;
}

.chartPaper {
  width: 100%;
  height: 100%;
  box-shadow: none;
  background: none;
}

.customScrollBar::-webkit-scrollbar,
.tableContainer::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 10px;
}

.fc-scroller::-webkit-scrollbar {
  width: 0px;

}

.requestIconStatus {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 30px;
  height: 30px;
  background: red;
  border-radius: 100%;
  text-align: center;
  color: #fff;
  justify-content: center;
  display: flex;
  font-size: 21px;
}

.imgBox {
  position: relative;
}

.requestIconStatusManager {
  width: 20px;
  height: 20px;
  right: 5px;
  top: auto;
  bottom: -2px;
  font-size: 14px;
}

.mobileNumberInput {
  display: flex;
  flex: 1;
  height: 100%;
  background-color: #f8fafb;
  border-color: transparent;
  padding: 10px;
  color: #44566c;
  font-family: Roboto;
  font-size: 15px;
}

.smallHint {
  color: #44566c;
  font-size: 13px;
  text-align: left;
  margin-bottom: 5px;
}

.mobileNumberInputFa {
  display: flex;

  margin-top: 20px;
}

.mobileInputContainer {
  width: 350px;
  margin: auto;
}

.mobileNumberInputDropDown {
  position: relative;
  background: #f8fafb;
  width: 130px;
  height: 43px;
}

.mobileNumberInputDropDown .input-with-icon img {
  width: 21px !important;
  height: 21px !important;
}

.mobileNumberInputDropDown .drop-down-icon {
  right: 0 !important;
}

.left-10 small {
  left: 20px !important;
}

.deep-sky-blue-text {
  cursor: pointer;
}

.flex-display {
  display: flex;
}

.requestIconStatus img {
  width: 70%;
}

.OPEN {
  background: #007bff;
}

.allCount {
  background: #252289
}

.ACTIVE {
  background: #007bff;

}

.ACCEPTED {
  background: #09b66d;
}

.REJECTED {
  background: #e63143;
}






.table_span_NOT_SUBMITTED,
.NOT_SUBMITTED {
  background: #14123F;
}

.table_span_NOT_VERIFIED,
.NOT_VERIFIED {
  background: #FDBF5E;
}

.table_span_VERIFIED,
.VERIFIED {
  background: #09B66D;
}

.table_span_DISABLED,
.DISABLED {
  background: #FA5A70;
}

.table_span_CANCEL,
.CANCEL {
  background: #fa5a5a;
}

.CANCEL_NON_OPEN {
  background: #bd4646;
}

.DISPATCHING {
  background: #FDBF5E;
}





.fc-daygrid-dot-event .fc-event-title {
  color: #fff;
}

.fc-event-time {
  color: #fff !important;
  margin-right: 15px !important;
}

.fc-daygrid-dot-event.fc-event-mirror,
.fc-daygrid-dot-event:hover {
  background: #000 !important;
  border: 1px solid red;
  border-radius: 5px;
}

.fc-next-button {
  margin-left: 10px !important;
}

.startDispatchingDisable {
  background: #3a3a3a !important;
  opacity: 0.3;
}

.customScrollBar::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

.customScrollBar::-webkit-scrollbar-thumb {
  background: #c8c8c8;
  border-radius: 10px;
}

.customScrollBar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.TableSelectVehiclType {
  display: flex;
}

.SingleType {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 15px;
}

.activeType img {
  border: 2px solid #0081ff;
  padding: 3px;
}

.SingleType img {
  width: 60px;
  box-shadow: rgba(0, 0, 0, 0.24) 0 2px 10px;
  border-radius: 100%;
  padding: 3px;
  background-color: #fff;
  margin-bottom: 10px;
}

.ChartParent {
  display: flex;
  flex: 1;
}

.chartsContainer div:first-child {
  /*height: 100%;*/
}

.chartsContainer canvas {
  /*height: 100% !important;*/
}

.chartSubText {
  width: 100%;
  text-align: center;
  margin-top: -10px;
  color: #44566c;
  white-space: nowrap;
}

.graphItemSmallFa {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 10px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.1);
}

.graphItemSmall {
  display: flex;
  flex-direction: row;
  height: 100%;
}

div[class^="Component-root-"] {
  height: 100% !important;
}

.graph-1-data .title {
  color: #44566c;
  font-weight: bold;
  font-size: 20px;
}

.rdrDefinedRangesWrapper {
  width: 160px !important;
}

.graph-1-data .sub-title {
  color: #44566c;
  font-size: 15px;
  margin: 10px 0;
}

.percent {
  font-size: 30px;
  color: #44566c;
}

.percent span {
  font-size: 16px;
}

.graph-center-content {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  flex-direction: column;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.graph-center-content div.subText {
  color: #44566c;
  font-size: 13px;
}

.graph-1-data .average {
  font-size: 24px;
  color: #44566c;
  display: flex;
}

.graph-1-data .average-number {
  background: #09b66d;
  margin-top: 5px;
  border-radius: 10px;
  padding: 6px;
  font-size: 12px;
  color: #fff;
}

.graph-1-data .average .average-icon {
  width: 60px;
  height: 25px;
  margin-top: 5px;
  background-size: 100% auto;
}

.table thead th,
.table tbody td {
  border-bottom: 1px solid #eaedf0;
  position: relative;
}

.progressBar {
  background: #d8d8d8;
  width: 100px;
  margin-left: 10px;
  height: 15px;
}

.tdData {
  font-size: 15px;
  color: #44566c;
}

.tdData span {
  color: #44566c;
  font-size: 15px;
}

.progressBar span {
  height: 100%;
  display: flex;
}

.up span {
  background: #09b66d;
}

.down span {
  background: #ff3d57;
}

.rankNum {
  padding-left: 25px !important;
  width: 60px;
}

.iconTd {
  width: 25px;
}

.graph-1-data .Detail {
  position: absolute;
  left: 10px;
  bottom: 10px;
  font-size: 15px;
  color: #44566c;
  text-decoration: underline;
}

.jss1 {
  height: 100% !important;
}

.mapboxgl-ctrl-fullscreen {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' xmlns='http://www.w3.org/2000/svg' fill='%23333'%3E%3Cpath d='M24 16v5.5c0 1.75-.75 2.5-2.5 2.5H16v-1l3-1.5-4-5.5 1-1 5.5 4 1.5-3h1zM6 16l1.5 3 5.5-4 1 1-4 5.5 3 1.5v1H7.5C5.75 24 5 23.25 5 21.5V16h1zm7-11v1l-3 1.5 4 5.5-1 1-5.5-4L6 13H5V7.5C5 5.75 5.75 5 7.5 5H13zm11 2.5c0-1.75-.75-2.5-2.5-2.5H16v1l3 1.5-4 5.5 1 1 5.5-4 1.5 3h1V7.5z'/%3E%3C/svg%3E");
  display: none !important;
}

.mapboxgl-ctrl-shrink {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.5 16c-1.75 0-2.5.75-2.5 2.5V24h1l1.5-3 5.5 4 1-1-4-5.5 3-1.5v-1h-5.5zM13 18.5c0-1.75-.75-2.5-2.5-2.5H5v1l3 1.5L4 24l1 1 5.5-4 1.5 3h1v-5.5zm3-8c0 1.75.75 2.5 2.5 2.5H24v-1l-3-1.5L25 5l-1-1-5.5 4L17 5h-1v5.5zM10.5 13c1.75 0 2.5-.75 2.5-2.5V5h-1l-1.5 3L5 4 4 5l4 5.5L5 12v1h5.5z'/%3E%3C/svg%3E");
}

.mapboxgl-ctrl-zoom-in {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' xmlns='http://www.w3.org/2000/svg' fill='%23333'%3E%3Cpath d='M14.5 8.5c-.75 0-1.5.75-1.5 1.5v3h-3c-.75 0-1.5.75-1.5 1.5S9.25 16 10 16h3v3c0 .75.75 1.5 1.5 1.5S16 19.75 16 19v-3h3c.75 0 1.5-.75 1.5-1.5S19.75 13 19 13h-3v-3c0-.75-.75-1.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.mapboxgl-ctrl-zoom-out {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' xmlns='http://www.w3.org/2000/svg' fill='%23333'%3E%3Cpath d='M10 13c-.75 0-1.5.75-1.5 1.5S9.25 16 10 16h9c.75 0 1.5-.75 1.5-1.5S19.75 13 19 13h-9z'/%3E%3C/svg%3E");
}

.mapboxgl-ctrl-compass {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' xmlns='http://www.w3.org/2000/svg' fill='%23333'%3E%3Cpath d='M10.5 14l4-8 4 8h-8z'/%3E%3Cpath d='M10.5 16l4 8 4-8h-8z' fill='%23ccc'/%3E%3C/svg%3E");
}

.barChart-data {
  padding: 5px;
  padding-top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
}

.chartBarFa {
  position: absolute;
  width: 100%;
  left: 0;
}

.tableVehicle {
  /*display: block;*/
  /*overflow-x: scroll;*/
  /*overflow-y: visible;*/

  border-radius: 10px;
  box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.1);
}

.tableVehicle tbody {
  background: #fff;
}

.ov-none {
  overflow: hidden;
}

.pieChart {
  /*top: calc(-50% + 70px);*/
  /*position: absolute;*/
}

.tableVehicle td,
.tableVehicle th {
  border-top: 0;
  text-align: left;
  white-space: nowrap;
}

.tableVehicle thead th {
  text-transform: uppercase;
  color: #44566c;
  font-weight: 500;
  font-size: 0.9em;
  white-space: nowrap;
}

.tableVehicle thead th div.thCol {
  display: flex;
  font-weight: bold;
  text-shadow: 0 0 0 black;
}

.sortByItem {
  cursor: pointer;
}

.tableVehicle td {
  color: #44566c;
}

.tableRow {
  cursor: pointer;
}

.tableRow td img {
  width: 40px;
  border-radius: 3px;
  margin-right: 5px;
}

/* 
.tableRow td:nth-child(odd) {
  color: #8697a8;
} */

.tableVehicle tbody tr:nth-child(odd) {
  background: #f8fafb;
}

.tableRow:hover {
  border-left: 5px solid #2d17f8;
}


i.active {
  background: #09b66d;
}

i.deactivate {
  background: #ff3d57;
}

i.pending {
  background: #fdbf5e;
}

.colDetails {
  width: 20px;
  cursor: pointer;
  height: auto !important;
}

.colDetails img {
  width: 100%;

}

.colDetailsFa {
  height: 20px;
}

.colDetailsFa img {
  width: 20px !important;
}

.pagination {
  margin-top: 10px;
  display: flex;
  list-style: none;
  outline: none;
  background: #fff;
  box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.25);
  height: 40px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.pagination>.active>a {
  background-color: #47ccde;
  border: none;
  box-shadow: 0 8px 16px 0 rgba(0, 129, 255, 0.2);
  color: #fff;
}

.pagination>li {
  height: 100%;
  display: flex;
}

.pagination>li>a {
  padding: 10px;
  outline: none;
  cursor: pointer;
}

.pagination .next,
.pagination .previous {
  background: #0081ff;
  height: 100%;
  display: flex;
  color: #fff;
  justify-content: center;
  align-items: center;
}

.pagination .next a {
  /* background: url("/assets/icons/ArrowRight.svg") center no-repeat; */
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='22' height='22' viewBox='0 0 22 22'%3E%3Cdefs%3E%3Cfilter id='ydr9zqaj9a' width='137.4%25' height='485%25' x='-18.7%25' y='-192.5%25' filterUnits='objectBoundingBox'%3E%3CfeOffset dy='8' in='SourceAlpha' result='shadowOffsetOuter1'/%3E%3CfeGaussianBlur in='shadowOffsetOuter1' result='shadowBlurOuter1' stdDeviation='8'/%3E%3CfeColorMatrix in='shadowBlurOuter1' result='shadowMatrixOuter1' values='0 0 0 0 0.662745098 0 0 0 0 0.760784314 0 0 0 0 0.819607843 0 0 0 0.1 0'/%3E%3CfeMerge%3E%3CfeMergeNode in='shadowMatrixOuter1'/%3E%3CfeMergeNode in='SourceGraphic'/%3E%3C/feMerge%3E%3C/filter%3E%3Cpath id='cwu0svkelb' d='M9.692 6.273c.36.352 3.881 4.048 3.881 4.048.193.189.29.434.29.68 0 .246-.097.491-.29.678 0 0-3.52 3.698-3.88 4.048-.361.352-1.01.376-1.393 0-.385-.374-.415-.897 0-1.357l3.23-3.369-3.23-3.37c-.415-.459-.385-.983 0-1.358.383-.376 1.032-.352 1.392 0z'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg%3E%3Cg%3E%3Cg filter='url(%23ydr9zqaj9a)' transform='translate(-1380 -844) translate(268 100) translate(896 735)'%3E%3Cg%3E%3Cg transform='translate(207) translate(9 9)'%3E%3Cmask id='2bh15xp6kc' fill='%23fff'%3E%3Cuse xlink:href='%23cwu0svkelb'/%3E%3C/mask%3E%3Cuse fill='%2344566C' fill-rule='nonzero' xlink:href='%23cwu0svkelb'/%3E%3Cg fill='%23FFF' fill-rule='nonzero' mask='url(%232bh15xp6kc)'%3E%3Cpath d='M0 0H22V22H0z'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
  background-position: center;
}

.pagination .previous a {
  /* background: url("/assets/icons/ArrowLeft.svg") center no-repeat; */
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='22' height='22' viewBox='0 0 22 22'%3E%3Cdefs%3E%3Cfilter id='oxiy5mdiea' width='137.4%25' height='485%25' x='-18.7%25' y='-192.5%25' filterUnits='objectBoundingBox'%3E%3CfeOffset dy='8' in='SourceAlpha' result='shadowOffsetOuter1'/%3E%3CfeGaussianBlur in='shadowOffsetOuter1' result='shadowBlurOuter1' stdDeviation='8'/%3E%3CfeColorMatrix in='shadowBlurOuter1' result='shadowMatrixOuter1' values='0 0 0 0 0.662745098 0 0 0 0 0.760784314 0 0 0 0 0.819607843 0 0 0 0.1 0'/%3E%3CfeMerge%3E%3CfeMergeNode in='shadowMatrixOuter1'/%3E%3CfeMergeNode in='SourceGraphic'/%3E%3C/feMerge%3E%3C/filter%3E%3Cpath id='lclz37j17b' d='M9.692 6.273c.36.352 3.881 4.048 3.881 4.048.193.189.29.434.29.68 0 .246-.097.491-.29.678 0 0-3.52 3.698-3.88 4.048-.361.352-1.01.376-1.393 0-.385-.374-.415-.897 0-1.357l3.23-3.369-3.23-3.37c-.415-.459-.385-.983 0-1.358.383-.376 1.032-.352 1.392 0z'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg%3E%3Cg%3E%3Cg filter='url(%23oxiy5mdiea)' transform='translate(-1172 -844) translate(268 100) translate(896 735)'%3E%3Cg transform='rotate(180 15 15.5)'%3E%3Cmask id='m7ec8znmtc' fill='%23fff'%3E%3Cuse xlink:href='%23lclz37j17b'/%3E%3C/mask%3E%3Cuse fill='%23fff' fill-rule='nonzero' xlink:href='%23lclz37j17b'/%3E%3Cg fill='%23fff' fill-rule='nonzero' mask='url(%23m7ec8znmtc)'%3E%3Cpath d='M0 0H22V22H0z'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
  background-position: center;
}

.previous.disabled a {
  /* background: url("/assets/icons/ArrowLeftBlack.svg") center no-repeat !important; */
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='22' height='22' viewBox='0 0 22 22'%3E%3Cdefs%3E%3Cfilter id='oxiy5mdiea' width='137.4%25' height='485%25' x='-18.7%25' y='-192.5%25' filterUnits='objectBoundingBox'%3E%3CfeOffset dy='8' in='SourceAlpha' result='shadowOffsetOuter1'/%3E%3CfeGaussianBlur in='shadowOffsetOuter1' result='shadowBlurOuter1' stdDeviation='8'/%3E%3CfeColorMatrix in='shadowBlurOuter1' result='shadowMatrixOuter1' values='0 0 0 0 0.662745098 0 0 0 0 0.760784314 0 0 0 0 0.819607843 0 0 0 0.1 0'/%3E%3CfeMerge%3E%3CfeMergeNode in='shadowMatrixOuter1'/%3E%3CfeMergeNode in='SourceGraphic'/%3E%3C/feMerge%3E%3C/filter%3E%3Cpath id='lclz37j17b' d='M9.692 6.273c.36.352 3.881 4.048 3.881 4.048.193.189.29.434.29.68 0 .246-.097.491-.29.678 0 0-3.52 3.698-3.88 4.048-.361.352-1.01.376-1.393 0-.385-.374-.415-.897 0-1.357l3.23-3.369-3.23-3.37c-.415-.459-.385-.983 0-1.358.383-.376 1.032-.352 1.392 0z'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg%3E%3Cg%3E%3Cg filter='url(%23oxiy5mdiea)' transform='translate(-1172 -844) translate(268 100) translate(896 735)'%3E%3Cg transform='rotate(180 15 15.5)'%3E%3Cmask id='m7ec8znmtc' fill='%23989898'%3E%3Cuse xlink:href='%23lclz37j17b'/%3E%3C/mask%3E%3Cuse fill='%23989898' fill-rule='nonzero' xlink:href='%23lclz37j17b'/%3E%3Cg fill='%23989898' fill-rule='nonzero' mask='url(%23m7ec8znmtc)'%3E%3Cpath d='M0 0H22V22H0z'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
  background-position: center;
}

.next.disabled a {
  /* background: url("/assets/icons/ArrowRightBlack.svg") center no-repeat !important; */
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='22' height='22' viewBox='0 0 22 22'%3E%3Cdefs%3E%3Cfilter id='ydr9zqaj9a' width='137.4%25' height='485%25' x='-18.7%25' y='-192.5%25' filterUnits='objectBoundingBox'%3E%3CfeOffset dy='8' in='SourceAlpha' result='shadowOffsetOuter1'/%3E%3CfeGaussianBlur in='shadowOffsetOuter1' result='shadowBlurOuter1' stdDeviation='8'/%3E%3CfeColorMatrix in='shadowBlurOuter1' result='shadowMatrixOuter1' values='0 0 0 0 0.662745098 0 0 0 0 0.760784314 0 0 0 0 0.819607843 0 0 0 0.1 0'/%3E%3CfeMerge%3E%3CfeMergeNode in='shadowMatrixOuter1'/%3E%3CfeMergeNode in='SourceGraphic'/%3E%3C/feMerge%3E%3C/filter%3E%3Cpath id='cwu0svkelb' d='M9.692 6.273c.36.352 3.881 4.048 3.881 4.048.193.189.29.434.29.68 0 .246-.097.491-.29.678 0 0-3.52 3.698-3.88 4.048-.361.352-1.01.376-1.393 0-.385-.374-.415-.897 0-1.357l3.23-3.369-3.23-3.37c-.415-.459-.385-.983 0-1.358.383-.376 1.032-.352 1.392 0z'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg%3E%3Cg%3E%3Cg filter='url(%23ydr9zqaj9a)' transform='translate(-1380 -844) translate(268 100) translate(896 735)'%3E%3Cg%3E%3Cg transform='translate(207) translate(9 9)'%3E%3Cmask id='2bh15xp6kc' fill='%23fff'%3E%3Cuse xlink:href='%23cwu0svkelb'/%3E%3C/mask%3E%3Cuse fill='%2344566C' fill-rule='nonzero' xlink:href='%23cwu0svkelb'/%3E%3Cg fill='%23FFF' fill-rule='nonzero' mask='url(%232bh15xp6kc)'%3E%3Cpath d='M0 0H22V22H0z'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
  background-position: center;
}

.pagination .next a,
.pagination .previous a {
  color: #fff;
  padding: 15px;
  height: auto;
}

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
  background-color: #0081ff;
  outline: none;
}

.pagination .disabled {
  background: #fff;
}

.pagination>li>a,
.pagination>li>span {
  color: #5a7089;
  font-family: Roboto;
  font-size: 0.8em;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span,
.pagination>li:last-child>a,
.pagination>li:last-child>span {
  border-radius: unset;
}

.tableFooter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.tableFooterDiv {
  display: flex;
  flex: 1 1;
  align-items: flex-start;
  justify-content: center;
  height: 190px;
  padding-top: 25px;
}

.tableFooterDiv select {
  width: 90px;
  box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.1);
  background-color: #ffffff;
  margin-left: 10px;
}

.itemsCount {
  color: #8697a8;
  font-size: 15px;
}

.showRows {
  justify-content: flex-start;
}

.showRows span {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.paginationDiv {
  justify-content: flex-end;
}

.tableSearch {
  display: flex;
}

.createBtn {
  width: 35px;
  height: 35px;
  box-shadow: 0 8px 16px 0 rgba(0, 129, 255, 0.2);
  border-radius: 100%;
  margin-left: 20px;
  /* background: url("/assets/icons/plus-white.svg") no-repeat; */
  background: no-repeat url("data:image/svg+xml,%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='459.325px' height='459.325px' viewBox='0 0 459.325 459.325' style='enable-background:new 0 0 459.325 459.325;' xml:space='preserve'%3E%3Cg%3E%3Cpath fill='%23fff' d='M459.319,229.668c0,22.201-17.992,40.193-40.205,40.193H269.85v149.271c0,22.207-17.998,40.199-40.196,40.193 c-11.101,0-21.149-4.492-28.416-11.763c-7.276-7.281-11.774-17.324-11.769-28.419l-0.006-149.288H40.181 c-11.094,0-21.134-4.492-28.416-11.774c-7.264-7.264-11.759-17.312-11.759-28.413C0,207.471,17.992,189.475,40.202,189.475h149.267 V40.202C189.469,17.998,207.471,0,229.671,0c22.192,0.006,40.178,17.986,40.19,40.187v149.288h149.282 C441.339,189.487,459.308,207.471,459.319,229.668z'/%3E%3C/g%3E%3C/svg%3E%0A");
  background-size: 50%;
  background-position: center;

  background-color: #0081ff;
  cursor: pointer;
  margin-top: 2.5px;
}

.addRequestBtn {
  position: absolute;
  top: 11px;
  right: 320px;
}

.ColorSelect {}

.colorInput {
  width: 100%;
  padding: 10px;
}

.colorInput span {
  width: 100%;
  padding: 10px;
  display: block;
}

.colorsList {
  display: flex;
  flex-wrap: wrap;
  background: #f8fafb;
  padding: 5px;
}

.colorsList div {
  margin: 2px;
}

.search-input-table .input-with-icon {
  background: #fff;
  box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.1);
  border-radius: 20px;
}

.search-input-table .input-with-icon img {
  width: 22px;
  height: 22px;
  left: 10px;
  top: 8px;
}

::placeholder {
  color: #8697a8;
}

:-ms-input-placeholder {
  color: #8697a8;
}

::-ms-input-placeholder {
  color: #8697a8;
}

.emptyTable {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 130px;
  color: #a6b1bd;
}

#center-axis-container g path {
  fill: #ff8a48 !important;
}

.colEditPopup {
  width: 100%;
  height: 100%;
  background: rgba(68, 86, 108, 0.15);
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  z-index: 999999999;
  justify-content: center;
  align-items: center;
}

.colFilter {
  max-height: 350px;
  overflow-y: scroll;
  background: #fff;
  padding: 15px;
  border-radius: 5px;
  z-index: 999999999;
  display: flex;
  flex-direction: column;
}

.filterRow {
  padding: 10px;
  color: #44566c;
  cursor: pointer;
}

.filterRow input {
  margin-right: 10px;
}

.thDetails {
  position: relative;
}

.colFilterIcon {
  width: 25px;
  height: 25px;
  margin-top: -5px;
  margin-right: 5px;
}

.editView {
  position: fixed;
  left: 0px;
  top: 0;
  width: 100vw;
  height: 100%;
  background: rgba(68, 86, 108, 0.4);
  z-index: 999999999999;
}

.editForm {
  width: 450px;
  height: 100vh;
  background: #fff;
  float: right;
  padding: 20px 30px;
  overflow-y: scroll;
  position: fixed;
  right: 0;
  flex-direction: column;
  display: flex;
}

.editForm input[type="color"] {
  width: 50px;
  height: 50px;
  padding: 3px;
}

.header-account-profileImage {
  overflow: hidden;
  width: 40px;
}

.header-account-profileImage img {
  min-height: 100%;
  min-width: 100%;
}

.dropDownLoading {
  display: flex;
  justify-content: center;
  padding: 5px;
  color: #8697a8;
}

.dropDownSearchInput {
  padding-left: 40px !important;
}

.required {
  color: red;
}

.noDataAvailable {
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.tagTable {
  padding: 3px 5px;
  border-radius: 5px;
  background: #efeeee;
  box-shadow: -2px 2px #b5b5b5;
  float: left;
  margin: 5px 5px 0 0;
  font-size: 16px;
}

.tagTable img {
  width: 25px;
  margin-left: 10px;
  background: #ccc;
  padding: 5px;
  border-radius: 100%;
  cursor: pointer;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.editForm .title {
  font-family: Roboto;
  font-size: 30px;
  font-weight: bold;
  color: #44566c;
  margin-bottom: 20px;
}

.editRowTitle {
  text-transform: capitalize;
  font-size: 15px;
  color: #8697a8;
  margin-bottom: 10px;
}

.editRowTitle strong {
  font-size: 15px;
  text-transform: none;
  font-weight: 400;
}

.inputLink {
  height: 42.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f8fafb;
}

.editRow {
  margin-bottom: 10px;
}

.dropwithActoin {
  display: flex;
  flex-direction: column;
}

.dropwithActoin .drop-down-menu {
  width: 85%;
}

.editRow input {
  border: none;
  background: #f8fafb;
  width: 100%;
  padding: 10px;
  font-family: Roboto;
  font-size: 15px;
  color: #44566c;
}

.dropwithActoin .addActoin {
  display: flex;
  background: #007bff;
  border-radius: 3px;
  flex: 1 1;
  padding: 10px;
  color: #fff;
  margin-left: 5px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  align-self: flex-end;
}

.groupView {
  width: 350px;
}

.groupViewTitle {
  font-family: Roboto;
  font-size: 24px;
  font-weight: bold;
  color: #44566c;
  margin-bottom: 20px;
}

.editFormSave {
  width: 100%;
  /* position: absolute; */
  background: #fff;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  /* padding: 10px; */
  /* padding-bottom: 20px; */
  margin-top: 50px;
}

.rowTagItem {}

.rowTagItem span {
  padding: 10px;
  border-radius: 3px;
  background: #0c5460;
  color: #fff;
  margin-right: 10px;
}

.editAction {
  padding: 20px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  margin-left: 15px;
  border: none;
  box-shadow: none;
}

.editActionSave {
  background: #0081ff;
  color: #fff;
}

.editActionSave img {
  margin-right: 5px;
  width: 18px;
}

.row-vehicle-icon {
  width: 50px;
}

.row-vehicle-icon img {
  width: 25px;
  height: auto;
  margin: 10px;
}

.editActionCancel {
  background: rgba(0, 128, 255, 0.2);
  color: #0081ff;
}

.editActionCancel {
  background: rgba(0, 128, 255, 0.2);
  color: #0081ff;
}

.editFormCreate {
  justify-content: flex-end;
}

.editActionDelete {
  color: #ff3d57;
  border-radius: 5px;
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border: solid 1px #ff3d57;
  margin-left: 0 !important;
}

.closeParent {
  display: flex;
  flex: 1;
}

.tableContainer {
  /*max-width:  calc( 100vw - 280px );*/
  /*overflow: scroll;*/
  box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.1);
}

.tableViewContainer {
  /* flex: 1; */
  overflow: scroll;
  max-width: calc(100vw - 115px);
}


.ip {
  width: 16em;
  height: 8em;
}

.ip__track {
  stroke: hsl(var(--hue), 90%, 90%);
  transition: stroke var(--trans-dur);
}

.ip__worm1,
.ip__worm2 {
  animation: worm1 2s linear infinite;
}

.ip__worm2 {
  animation-name: worm2;
}

/* Dark theme */
@media (prefers-color-scheme: dark) {
  :root {
    --bg: hsl(var(--hue), 90%, 5%);
    --fg: hsl(var(--hue), 90%, 95%);
  }

  .ip__track {
    stroke: hsl(var(--hue), 90%, 15%);
  }
}

/* Animation */
@keyframes worm1 {
  from {
    stroke-dashoffset: 0;
  }

  50% {
    animation-timing-function: steps(1);
    stroke-dashoffset: -358;
  }

  50.01% {
    animation-timing-function: linear;
    stroke-dashoffset: 358;
  }

  to {
    stroke-dashoffset: 0;
  }
}

@keyframes worm2 {
  from {
    stroke-dashoffset: 358;
  }

  50% {
    stroke-dashoffset: 0;
  }

  to {
    stroke-dashoffset: -358;
  }
}



.loader {
  position: relative;
}

.lds-ripple {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 190px;
}

.lds-ripple div {
  position: absolute;
  border: 2px solid #007bff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

.lds-ripple div:nth-child(3) {
  animation-delay: -1s;
}

.lds-ripple div:nth-child(4) {
  animation-delay: -1.5s;
}

@keyframes lds-ripple {
  0% {
    top: 90px;
    left: 90px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0px;
    left: 0px;
    width: 180px;
    height: 180px;
    opacity: 0;
  }
}

.Toastify__toast-container div {
  z-index: 99999999999 !important;

}

.Toastify__toast-container--top-right {
  z-index: 99999999999 !important;

}

.passchangeinput .input-with-label-container {
  width: 100%;
}

.passchangeinput .input-with-label-container .input-with-label input {
  min-width: auto;
}

.alert-danger {
  background-color: #ff778a !important;
}

.content-container {
  /*position: relative; for Table Edit View*/
  /* display: flex; */
  z-index: 9999999999;
}

main.content-container>div:first-child {
  width: 100%;
}

.InputError {
  border: 2px solid #ff354b !important;
}

.searchResBox {
  width: 100%;
  padding: 10px;
  background: #f8fafb;
  height: 150px;
  overflow-y: scroll;
  border: 2px solid #dbdcdd;
  margin-bottom: 30px;
  margin-top: 10px;
  border-radius: 10px;
}

.searchResBoxItem {
  padding: 5px;
  border-bottom: 2px solid #dbdcdd;
  cursor: pointer;
}

.searchResBoxItemActive {
  background: #bbbcbd;
}

.LoadingButton {
  cursor: pointer;
  border: none;
  outline: none;
}

.login-form-inputs-container {
  width: 300px;
}

/*.dashboardSection .react-grid-item{*/
/*    transform: scale(.5);*/
/*}*/

/*.OverView{*/
/*  width: 100%;*/
/*  height: 500px;*/
/*  display: flex;*/
/*  background: red;*/
/*}*/
/*.overViewSection{*/
/*  padding: 10px;*/
/*  background: black;*/
/*  display: flex;*/
/*  flex: 1;*/
/*  margin: 10px;*/
/*  width: 30%;*/
/*}*/

.companyLogoEdit {}

.companyLogoEdit .input-with-label {
  height: 120px !important;
}

.companyLogoEdit .account-image-section,
.companyLogoEdit .picked-image-container,
.companyLogoEdit .custom-file-upload,
.companyLogoEdit .image-picker-button-container {
  width: 100% !important;
  height: 120px !important;

  border-radius: 5px;
}

.ErrorInputBorder {
  border: #e01730 2px solid !important;
}

.ErrorSpan {
  color: #e01730;
}

.inviteView {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999999999999999;
  background-color: #7f7f7f94;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.copyLink button {
  background: none;
  border: none;
  color: #0081ff;
  cursor: pointer;
}

.inviteViewContent {
  background: #fff;
  padding: 20px;
  position: relative;
  max-height: 90%;
  width: 600px;
}

.inviteViewContent .title {
  font-family: Roboto;
  font-size: 30px;
  font-weight: bold;
  color: #44566c;
}

.inviteForm {
  width: 550px;
  display: flex;
  flex-direction: column;
  height: 350px;
}

.inviteLinkFa {
  width: 100%;
  word-wrap: break-word;
}

.inviteForm .input-with-icon img {
  width: 20.7px !important;
  height: 18.7px !important;
}

.inviteForm span {
  font-family: Roboto;
  font-size: 15px;
  color: #8697a8;
}

.inviteForm strong {
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  color: #44566c;
  margin-bottom: 20px;
}

.inviteForm textarea {
  background: #f8fafb;
  border: none;
  height: 250px;
  max-height: 150px;
  min-height: 150px;
  padding: 20px;
}

.sendInvite {
  padding: 10px 30px;
  color: #fff;
  background: #0081ff;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
}

.inviteAction {
  display: flex;
}

.copyLink span {
  cursor: pointer;
}

.copyLink {
  display: flex;
  align-items: center;
  flex: 1;
  color: #0081ff;
}

.copyLink img {
  margin-right: 10px;
}

.inviteForm textarea:focus {
  outline: none;
}

.inviteLink span {
  color: #44566c;
  font-size: 15px;
  font-weight: 500;
  margin-left: 10px;
}

.inviteLink {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  /* background: rgb(248, 250, 251); */
  padding: 10px;
  cursor: pointer;
  /* border-top: 1px solid #b3c0ce; */
}

.inviteLinkMobile img {
  width: 100%;
}

.fc-direction-ltr {
  display: flex;
  flex: 1;
}

.calendarFa {
  height: 100%;
  display: flex;
  padding: 5px;
  padding-bottom: 20px;
  position: relative;
}

.OpenRequests {
  width: 300px;
  margin-top: 62px;
  background: #fff;
  box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.1);
  margin-left: 5px;
  margin-right: 5px;
  overflow-y: scroll;
  border-radius: 7px;
  max-height: 100vh;
}

.OpenRequestsUser {
  margin-top: 61px;
}

.OpenRequests .title {
  font-family: Roboto;
  font-size: 22px;
  font-weight: bold;
  color: #44566c;
  text-align: center;
  padding: 7px 20px;

  border-bottom: 2px solid #eaedf0;
}

.overlabEvent {
  /* padding-top: 10% */
}

.eventCustomStyle {
  padding: 5px;
  border-radius: 3px;
}

.fc .fc-timegrid-now-indicator-line {
  border-width: 5px;
  border-style: dashed;
  opacity: 0.8;
}

.opacityEvent {
  opacity: 0.4;
}

.CalendarView {
  display: flex;
  flex: 1;
}

.rbc-allday-cell {
  /* display: none !important; */
  background: #b7bfd3;
}

.rbc-current-time-indicator {
  background: #fff;
  border-bottom: 5px red dashed;
}

.rbc-calendar {
  flex: 1;
}

.rbc-event:focus {
  outline: none !important;
  background-color: none !important;
  opacity: 1 !important;
}

.rbc-month-view {
  padding: 0px;
  background: #fff;
  box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.1);
  border: none;
  border-radius: 7px;
}

.rbc-toolbar {
  /*display: block;*/
  padding: 10px;
  justify-content: center;
  /*width:  calc( 100% - 300px );*/
  /*background: red;*/
}

.rbc-toolbar button {
  background: #fff;
  cursor: pointer;
  border: none;
  padding: 10px 15px;
}

.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  background: #1d80fe;
  color: #fff;
  border-radius: 5px !important;
}

.rbc-btn-group button+button {
  margin-left: 5px !important;
  text-transform: capitalize;
}

.rbc-toolbar {
  justify-content: space-between;
}

/*.UserCalendarToolbar .rbc-toolbar {*/
/*  padding-right: 60px !important;*/
/*}*/

.CalendarViewBarOpen .rbc-toolbar {
  padding-right: 40% !important;
}

.rbc-toolbar-label {
  font-weight: bold;
}

.fileDocument {
  position: absolute;
  bottom: 20px;
  background: #eaedf0;
  border-radius: 5px;
  padding: 7px;
}

.rbc-toolbar *:focus {
  outline: none;
}

.rbc-header .rbc-button-link {
  font-size: 20px;
  padding-bottom: 30px;
}

.rbc-month-header .rbc-header {
  padding: 20px !important;
  font-size: 20px;
  color: #44566C;
}

.rbc-month-row+.rbc-month-row {
  border-top: 2px solid #eaedf0;
}

.fc-timeGridWeek-button {
  margin: 0 10px !important;
}

.pinMapIconStart {
  margin-top: -64px !important;
}

.pinMapIconEnd {
  margin-top: -64px !important;
}

.fc-button-group {
  padding: 7px;
  border-radius: 5px !important;
}

.fc .fc-button-group>.fc-button {
  background-color: none !important;
  border-radius: 5px !important;
  padding: 10px 40px;
  color: #fff;
  box-shadow: none !important;
  text-transform: capitalize !important;

  border: 0;
}

.fc .fc-button {
  text-transform: capitalize !important;

}

.fc .fc-button-group>.fc-button:hover {
  background-color: #1d80fe !important;
  box-shadow: none !important;
  border: 0;
  border-radius: 5px !important;
  color: #fff !important;
}

.fc .fc-button-primary:not(:disabled).fc-button-active,
.fc .fc-button-primary:not(:disabled):active {
  background-color: #1d80fe !important;
  box-shadow: none !important;
  border: 0;
}

.RequestsItem {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-bottom: 1px solid #eaedf0;
  position: relative;
}

.RequestsItem .imgBox {
  background: #e8e8e8;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  margin-right: 5px;
}

.RequestsItemHeader {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
}

.requestArrow {
  position: absolute;
  top: 25px;
  right: 20px;
  width: 20px;
  height: 20px;
  /* background: url("/assets/icons/icon-basic-arrow-right.svg") center no-repeat; */
  background-size: 100% auto;
  cursor: pointer;
}

.requestArrowDown {
  /* background: url("/assets/icons/icon-basic-arrow-down.svg") center no-repeat; */
  background-size: 100% auto;
}

.requestDataTitle {
  font-family: Roboto;
  font-size: 15px;
  font-weight: bold;
  color: #44566c;
}

.requestDataDay i {
  display: block;
  width: 8px;
  height: 8px;
  background: red;
  margin-right: 5px;
  border-radius: 100%;
}

.RequestDate {
  color: #8697a8;
  font-family: Roboto;
  font-size: 13px;
}

.RequestType {
  font-family: Roboto;
  font-size: 13px;
  color: #8697a8;
}

i.PAID {
  background: "#0081FF";
}

i.UNPAID {
  background: "#22CCE2";
}

i.SPECIAL {
  background: "#09B66D";
}

i.Sick {
  background: "#FF8A48";
}

.requestData {
  padding: 10px;
}

.none {
  display: none;
}

.requestDataNote {
  background: #f8fafb;
  padding: 10px;
  color: #44566c;
  margin-top: 5px;
  min-height: 70px;
  margin-bottom: 20px;
}

.requestDataNoteInput {
  border: none;
  width: 100%;
}

.inviteResTable {
  display: flex;
  flex-direction: column;
  background: #f8fafb;
  max-height: 100%;
  overflow-y: scroll;
}

.inviteResTable .inviteResTableRow:nth-child(odd) {
  background: #fff;
}

.inviteResTableRow {
  padding: 15px;
}

.inviteResTableRowData {
  display: flex;
  flex: 1;
}

.inviteResTableRow .divCol {
  display: flex;
  flex: 1;
  padding-left: 10px;
}

.inviteResTableRow img {
  width: 30px;
}

.inviteResTableRow .Pending {
  background: #ff3d57;
}

.inviteResTableRow {
  position: relative;
}

.inviteResTableRowStatus {
  position: absolute;
  right: 10px;
  top: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 100%;
}

.inviteResTableRow .Accepted {
  background: #09b66d;
}

.inviteResTableRow .Accepted img {
  width: 15px;
}

.RequestBtns {
  display: flex;
  justify-content: flex-end;
}

.RequestBtn {
  padding: 8px 20px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.RequestBtnAccept {
  background: #0081ff;
  color: #fff;
  font-size: 15px;
}

.RequestBtnReject {
  border: 2px solid #ff3d57;
  color: #ff3d57;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  font-size: 15px;
}

.toolbar-container {
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.back-next-buttons {
  background: #fff;
  box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.1);
  border-radius: 5px;
  margin-left: 2px;
}

.toolbar-container button {
  background: none;
  border: none;
  padding: 5px 20px;
}

.btn-next,
.btn-back {
  padding: 0px 15px !important;
  font-size: 25px;
  color: #44566c;
}

.btn-current {
  text-transform: capitalize;
  color: #44566c;
}

.requestLink {
  position: absolute;
  right: 10px;
  top: 11px;
  box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.1);
  background: #fff;
  width: 300px;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
}

.requestLinkUser {
  top: 8px;
}

.requestLink span {
  display: flex;
  flex: 1;
  align-items: center;
}

.requestLink i {
  display: block;
  width: 18px;
  height: 18px;
  margin-top: 4px;
  /* background: url("/assets/icons/icon-basic-arrow-down.svg") center no-repeat; */
  background-size: 100% auto;
}

.requestLink i.closeItem {
  /* background: url("/assets/icons/icon-basic-arrow-right.svg") center no-repeat; */
  background-size: 100% auto;
}

.DateInfo {
  display: flex;
}

.DateInfoCol {
  display: flex;
  flex-direction: column;
}

.DateInfoColData {
  background: #f8fafb;
}

.DateInfoCol span {
  color: #8697a8;
}

.DateInfoCol {
  display: flex;
  padding: 3px;
}

.DateInfoColData {
  padding: 6px;
  border-radius: 6px;
  font-family: Roboto;
  font-size: 15px;
  position: relative;
  height: 40px;
}

.DatePickerRequest {
  position: absolute;
  z-index: 9999;
  left: 0;
  top: 40px;
}

.requestDataTitle {
  margin-top: 10px;
}

.flex1 {
  display: flex;
  flex: 1;
}

.requestFile {
  display: flex;
  flex: 1;
  padding: 10px;
  margin-top: 5px;
  border-radius: 6px;
  background: #f8fafb;
  flex-direction: column;
}

.LinkInvite {
  padding: 5px;
  word-wrap: break-word;
  color: #44566c;
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
}

.RequestTypeFa {
  display: flex;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.RequestTypeItem {
  display: flex;
  justify-content: center;
  flex: 1;
  padding: 5px;
  margin: 5px;
  color: #44566c;
  cursor: pointer;
}

.DateInfoCol input {
  background: transparent;
  border: none;
  padding: 6px;
}

.RequestTypeItem i {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  margin-right: 10px;
  border: #eaedf0 2px solid;
}

.RequestTypeItem i.active {
  background: #0081ff;
}

.sendRequest {
  padding: 10px 30px;
  color: #fff;
  background: #0081ff;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  display: block;
  width: 200px;
  float: right;
  margin-top: 10px;
}

.num_days_input {
  display: block;
  width: 80px;
}

.langMenu {
  position: relative;
}

.langMenu-drop-menu {
  display: none;
  position: absolute;
  right: 0px;
  top: 30px;
  width: 183px;
  transition: 0.4s height;
  overflow: hidden;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.3);
  z-index: 999999999;
}

.langMenu:hover .langMenu-drop-menu {
  display: block;
}

.langMenuHome .header-language-icon {
  float: left !important;
}

.langMenuHome .header-language-container {
  height: 25px !important;
}

.profile-fa {
  background: #fff;
  box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.1),
    0 4px 8px 0 rgba(169, 194, 209, 0.25);
  border-radius: 10px;
  overflow-y: scroll;

}

.profile-edit-fa {
  width: 100%;
}

.account-fa {
  width: 410px !important;
}

.profile-fa .right-section {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.updateBtn {
  float: right;
  padding: 10px 30px;
  background: #0081ff;
  margin: 15px;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  width: 160px;
  height: 60px;
}

.updateUserBtn {
  /* position: absolute;
  bottom: 30px;
  right: 30px; */
}

.updateBtn img {
  margin-right: 5px;
}

.popupBg {
  position: fixed;
  left: 0px;
  top: 0;
  width: 100vw;
  height: 100%;
  background: rgba(68, 86, 108, 0.4);
  z-index: 999999999999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  padding: 10px 30px;
  padding-bottom: 50px;
  background: #fff;
  border-radius: 5px;
  position: relative;
}

.billingHistory {
  display: block;
  padding: 10px;
  height: 180px;
  overflow-y: scroll;
}

.billingHistoryItem {
  width: 100%;
  height: 70px;
  border-bottom: 2px solid #eaedf0;
  position: relative;
  display: flex;
  align-items: center;
}

.billingHistoryItem img {
  width: 50px;
}

.billingHistoryItemInfo {
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.billingHistoryItemInfo strong {
  color: #44566c;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
}

.billingHistoryItemInfo .ItemStatus {
  color: #8697a8;
  font-family: Roboto;
  font-size: 13px;
  text-decoration: underline;
  cursor: pointer;
}

.popup-content .add {
  width: 100%;
  background: #0081ff;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  margin-top: 20px;
  cursor: pointer;
}

.popup-content .add img {
  width: 22px;
  margin-right: 10px;
}

.popup-content-language {
  width: 350px;
}

.popup-content .title {
  color: #44566c;
  font-family: Roboto;
  font-size: 20px;
  font-weight: bold;
}

.profileSide {
  display: flex;
  flex: 1;
  width: 100%;

  flex-direction: column;
}

.profileSideItem {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.profileTabs {}

.profileTab {
  cursor: pointer;
  padding: 20px;
  border-left: 3px solid #ccc;
}

.profileTab img {
  width: 28px;
}

.profileTabActive {
  background: #fff;
  border-left: 3px solid #0081FF;
}

.checkboxWrapper {
  margin-top: 30px;
}

.checkboxDiv {
  display: flex;
  align-items: center;
  font-size: 26px;
  margin-top: 20px;
}

.chechboxDesc {
  font-size: 14px;
  margin-top: 15px;
}

.checkboxDiv input {
  height: 40px;
  width: 40px;
  margin-right: 10px;
}

.userEditTitle {
  color: #14123F;
  font-size: 27px;
  font-weight: bold;
}

.profileSideItemHeader {
  background: #fff;
  position: relative;
  width: 100%;
  padding: 6px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  color: #44566c;
  margin-top: 10px;
}

.profileSideItemHeader img {
  width: 17px;
}

.LicensesIcon {
  width: 35px;
}

.iconSideProfile {
  margin-right: 10px;
}

.profileSideItemLink {
  padding: 6px !important;
  color: #44566c;
  position: relative;
  font-size: 15px;
  margin-left: 15px;
  cursor: pointer;
  align-items: center;
  display: flex;
  margin-top: 5px;
}

.profileSideItemLink img {
  width: 25px;
  margin-right: 10px;
}

.profileSideItemHeader img.open,
.profileSide .delete {
  position: absolute;
  right: 10px;
}

.profileSide .delete {
  display: none;
}

.profileSideItemLink:hover .delete {
  display: block;
}

.profile-fa .input-with-label input {
  min-width: 100% !important;
}

.profileSideColTitle {
  padding: 10px 0;
  display: flex;
  width: 100%;
  font-family: Roboto !important;
  font-size: 40px !important;
  font-weight: bold !important;
  color: #44566c !important;
}

.profile-fa .bar-right-section {
  padding: 30px 0;
  background: #f8fafb;
}

.wid100 .input-with-label-container {
  width: 100%;
}

.profileInputRows {
  display: flex;
}

.profileInput {
  background-color: #f8fafb;
  border-radius: 5px;
  position: relative;
  margin-top: 12px;
  padding-left: 16px;
  color: #44566c;
  font-family: Roboto;
  font-size: 15px;
  border: none;
  height: 40px;
  width: 31%;
  margin-right: 2%;
}

.inputColTitle {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  flex: 1;
}

.inputColTitle span {
  color: #8697a8;
  font-family: Roboto;
  font-size: 15px;
}

.inputColTitle input {
  margin-top: 3px;
  width: 100%;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

/*.profileColRow{*/
/*    align-self: end;*/
/*}*/
/*.profileSide{*/
/*    width: 50%;*/
/*}*/
.langMenuHome {
  margin: 0 !important;
  display: block !important;
}

.langMenu-drop-menu button {
  text-align: center;
  background: transparent;
  outline: none;
  font-family: Roboto;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: #44566c;
  padding: 10px;
  border: none;
  width: 100%;
  cursor: pointer;
}

.CountOfOpenReq {
  background: #0081ff;
  border-radius: 100%;
  margin-right: 10px;
  color: #fff;
  font-weight: 200;
  padding: 0 7px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.activeLang {
  background: #0081ff !important;
  color: #fff !important;
}

.header-drop-menu button:hover {
  background-color: #7bb7ff;
  color: white;
}

.ErrorMassage {
  color: #b21f2d;
}

.switchRole {
  font-family: Roboto;
  color: #44566c;
  padding: 35px !important;
  position: relative;
  border-radius: 3px;
}

.account-detailCol {
  flex-direction: column;
  flex: 1;
}

.switchRole div.title {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 20px;
}

.switchRole .input-with-label-container small,
.switchRole span {
  color: #8697a8;
  font-size: 15px;
}

.switchRole .updateBtn {
  margin-right: 0 !important;
}

.switchRoleFA {
  background-color: rgba(134, 151, 168, 0.5);
  position: fixed;
  top: -30px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  z-index: 9999999999;
  height: 100vh;
  width: 100%;
}

.divInput {
  width: 100%;
  height: 40px;
  background-color: #f8fafb;
  border-radius: 5px;

  color: #44566c;
  font-family: Roboto;
  font-size: 15px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.DatePickerLic {
  position: absolute;
  z-index: 9999;
  left: 0;
  top: 40px;
  width: 250%;
}

.popup-content {
  top: -60px;
}

.divInput .react-calendar {
  position: absolute;
  left: 0;
}

.groupsProfile {
  padding: 8px;
}

.groupsProfile span {
  padding: 5px 10px;
  background: #dadcdd;
  margin-right: 5px;
  color: #44566c;
  border-radius: 3px;
}

.noBills {
  text-align: center;
  width: 100%;
  display: block;
  color: #bec4c7;
}

.header-account-container {
  cursor: pointer;
}

.tableRowActive {
  background: #dedede !important;
}

.app_version {
  display: flex;
  flex: 1;
  text-align: center;
  justify-content: center;
  color: #737781;
}

.menuViews {
  display: flex;
}

.ulChildView {
  display: flex;
  background: #fff;
  padding-top: 85px;
  width: 500px;

  height: 100vh;
}

.menuFilterView {
  margin-left: 0;
  padding-top: 20px;
  min-width: 230px;
}

/* .UsermenuFilterView{
  width: 250px;

} */
.filtersView {
  /* position: fixed;
  top: 60px; */
  padding: 5px;
  background: #fff;
  padding-top: 50px;
  height: 300vh;
  width: 100%;
}

.filtersViewUsers {
  /* width: 250px; */

}

.taskContainerTable {
  width: calc(100vw - 280px) !important;
}

.containerTable {
  width: calc(100vw - 115px) !important;
}

.containerTableFilter {
  width: calc(100vw - 352px) !important;
}

.UserContainerTable {
  width: calc(100vw - 352px) !important;

}


.center-center,
.loader {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.loader {
  width: 25vw;
  height: 25vw;
}

.group {
  position: relative;
}

.dot {
  position: absolute;
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 50%;
}

.loader>.group:nth-child(1) {
  -webkit-animation: spin 1.75s ease infinite;
  -moz-animation: spin 1.75s ease infinite;
  -o-animation: spin 1.75s ease infinite;
  -ms-animation: spin 1.75s ease infinite;
  animation: spin 1.75s ease infinite;
  -webkit-transform-origin: 0.25rem 0.25rem;
  -moz-transform-origin: 0.25rem 0.25rem;
  -o-transform-origin: 0.25rem 0.25rem;
  -ms-transform-origin: 0.25rem 0.25rem;
  transform-origin: 0.25rem 0.25rem;
}

.loader>.group:nth-child(1)>.dot {
  background-color: #3b38a3;
}

.loader>.group:nth-child(1)>.dot:nth-child(1) {
  -webkit-transform: translate3d(2.25rem, 2.25rem, 0);
  -moz-transform: translate3d(2.25rem, 2.25rem, 0);
  -o-transform: translate3d(2.25rem, 2.25rem, 0);
  -ms-transform: translate3d(2.25rem, 2.25rem, 0);
  transform: translate3d(2.25rem, 2.25rem, 0);
}

.loader>.group:nth-child(1)>.dot:nth-child(2) {
  -webkit-transform: translate3d(-2.25rem, -2.25rem, 0);
  -moz-transform: translate3d(-2.25rem, -2.25rem, 0);
  -o-transform: translate3d(-2.25rem, -2.25rem, 0);
  -ms-transform: translate3d(-2.25rem, -2.25rem, 0);
  transform: translate3d(-2.25rem, -2.25rem, 0);
}

.loader>.group:nth-child(1)>.dot:nth-child(3) {
  -webkit-transform: translate3d(-2.25rem, 2.25rem, 0);
  -moz-transform: translate3d(-2.25rem, 2.25rem, 0);
  -o-transform: translate3d(-2.25rem, 2.25rem, 0);
  -ms-transform: translate3d(-2.25rem, 2.25rem, 0);
  transform: translate3d(-2.25rem, 2.25rem, 0);
}

.loader>.group:nth-child(1)>.dot:nth-child(4) {
  -webkit-transform: translate3d(2.25rem, -2.25rem, 0);
  -moz-transform: translate3d(2.25rem, -2.25rem, 0);
  -o-transform: translate3d(2.25rem, -2.25rem, 0);
  -ms-transform: translate3d(2.25rem, -2.25rem, 0);
  transform: translate3d(2.25rem, -2.25rem, 0);
}

.loader>.group:nth-child(2) {
  -webkit-animation: spin 2.5s ease infinite;
  -moz-animation: spin 2.5s ease infinite;
  -o-animation: spin 2.5s ease infinite;
  -ms-animation: spin 2.5s ease infinite;
  animation: spin 2.5s ease infinite;
  -webkit-transform-origin: 0.25rem 0.25rem;
  -moz-transform-origin: 0.25rem 0.25rem;
  -o-transform-origin: 0.25rem 0.25rem;
  -ms-transform-origin: 0.25rem 0.25rem;
  transform-origin: 0.25rem 0.25rem;
}

.loader>.group:nth-child(2)>.dot {
  background-color: #4d49be;
}

.loader>.group:nth-child(2)>.dot:nth-child(1) {
  -webkit-transform: translate3d(3.25rem, 3.25rem, 0);
  -moz-transform: translate3d(3.25rem, 3.25rem, 0);
  -o-transform: translate3d(3.25rem, 3.25rem, 0);
  -ms-transform: translate3d(3.25rem, 3.25rem, 0);
  transform: translate3d(3.25rem, 3.25rem, 0);
}

.loader>.group:nth-child(2)>.dot:nth-child(2) {
  -webkit-transform: translate3d(-3.25rem, -3.25rem, 0);
  -moz-transform: translate3d(-3.25rem, -3.25rem, 0);
  -o-transform: translate3d(-3.25rem, -3.25rem, 0);
  -ms-transform: translate3d(-3.25rem, -3.25rem, 0);
  transform: translate3d(-3.25rem, -3.25rem, 0);
}

.loader>.group:nth-child(2)>.dot:nth-child(3) {
  -webkit-transform: translate3d(-3.25rem, 3.25rem, 0);
  -moz-transform: translate3d(-3.25rem, 3.25rem, 0);
  -o-transform: translate3d(-3.25rem, 3.25rem, 0);
  -ms-transform: translate3d(-3.25rem, 3.25rem, 0);
  transform: translate3d(-3.25rem, 3.25rem, 0);
}

.loader>.group:nth-child(2)>.dot:nth-child(4) {
  -webkit-transform: translate3d(3.25rem, -3.25rem, 0);
  -moz-transform: translate3d(3.25rem, -3.25rem, 0);
  -o-transform: translate3d(3.25rem, -3.25rem, 0);
  -ms-transform: translate3d(3.25rem, -3.25rem, 0);
  transform: translate3d(3.25rem, -3.25rem, 0);
}

.loader>.group:nth-child(3) {
  -webkit-animation: spin 3.25s ease infinite;
  -moz-animation: spin 3.25s ease infinite;
  -o-animation: spin 3.25s ease infinite;
  -ms-animation: spin 3.25s ease infinite;
  animation: spin 3.25s ease infinite;
  -webkit-transform-origin: 0.25rem 0.25rem;
  -moz-transform-origin: 0.25rem 0.25rem;
  -o-transform-origin: 0.25rem 0.25rem;
  -ms-transform-origin: 0.25rem 0.25rem;
  transform-origin: 0.25rem 0.25rem;
}

.loader>.group:nth-child(3)>.dot {
  background-color: #3f3bb1;
}

.loader>.group:nth-child(3)>.dot:nth-child(1) {
  -webkit-transform: translate3d(4.25rem, 4.25rem, 0);
  -moz-transform: translate3d(4.25rem, 4.25rem, 0);
  -o-transform: translate3d(4.25rem, 4.25rem, 0);
  -ms-transform: translate3d(4.25rem, 4.25rem, 0);
  transform: translate3d(4.25rem, 4.25rem, 0);
}

.loader>.group:nth-child(3)>.dot:nth-child(2) {
  -webkit-transform: translate3d(-4.25rem, -4.25rem, 0);
  -moz-transform: translate3d(-4.25rem, -4.25rem, 0);
  -o-transform: translate3d(-4.25rem, -4.25rem, 0);
  -ms-transform: translate3d(-4.25rem, -4.25rem, 0);
  transform: translate3d(-4.25rem, -4.25rem, 0);
}

.loader>.group:nth-child(3)>.dot:nth-child(3) {
  -webkit-transform: translate3d(-4.25rem, 4.25rem, 0);
  -moz-transform: translate3d(-4.25rem, 4.25rem, 0);
  -o-transform: translate3d(-4.25rem, 4.25rem, 0);
  -ms-transform: translate3d(-4.25rem, 4.25rem, 0);
  transform: translate3d(-4.25rem, 4.25rem, 0);
}

.loader>.group:nth-child(3)>.dot:nth-child(4) {
  -webkit-transform: translate3d(4.25rem, -4.25rem, 0);
  -moz-transform: translate3d(4.25rem, -4.25rem, 0);
  -o-transform: translate3d(4.25rem, -4.25rem, 0);
  -ms-transform: translate3d(4.25rem, -4.25rem, 0);
  transform: translate3d(4.25rem, -4.25rem, 0);
}

.loader>.group:nth-child(4) {
  -webkit-animation: spin 4s ease infinite;
  -moz-animation: spin 4s ease infinite;
  -o-animation: spin 4s ease infinite;
  -ms-animation: spin 4s ease infinite;
  animation: spin 4s ease infinite;
  -webkit-transform-origin: 0.25rem 0.25rem;
  -moz-transform-origin: 0.25rem 0.25rem;
  -o-transform-origin: 0.25rem 0.25rem;
  -ms-transform-origin: 0.25rem 0.25rem;
  transform-origin: 0.25rem 0.25rem;
}

.loader>.group:nth-child(4)>.dot {
  background-color: #252289;
}

.loader>.group:nth-child(4)>.dot:nth-child(1) {
  -webkit-transform: translate3d(5.25rem, 5.25rem, 0);
  -moz-transform: translate3d(5.25rem, 5.25rem, 0);
  -o-transform: translate3d(5.25rem, 5.25rem, 0);
  -ms-transform: translate3d(5.25rem, 5.25rem, 0);
  transform: translate3d(5.25rem, 5.25rem, 0);
}

.loader>.group:nth-child(4)>.dot:nth-child(2) {
  -webkit-transform: translate3d(-5.25rem, -5.25rem, 0);
  -moz-transform: translate3d(-5.25rem, -5.25rem, 0);
  -o-transform: translate3d(-5.25rem, -5.25rem, 0);
  -ms-transform: translate3d(-5.25rem, -5.25rem, 0);
  transform: translate3d(-5.25rem, -5.25rem, 0);
}

.loader>.group:nth-child(4)>.dot:nth-child(3) {
  -webkit-transform: translate3d(-5.25rem, 5.25rem, 0);
  -moz-transform: translate3d(-5.25rem, 5.25rem, 0);
  -o-transform: translate3d(-5.25rem, 5.25rem, 0);
  -ms-transform: translate3d(-5.25rem, 5.25rem, 0);
  transform: translate3d(-5.25rem, 5.25rem, 0);
}

.loader>.group:nth-child(4)>.dot:nth-child(4) {
  -webkit-transform: translate3d(5.25rem, -5.25rem, 0);
  -moz-transform: translate3d(5.25rem, -5.25rem, 0);
  -o-transform: translate3d(5.25rem, -5.25rem, 0);
  -ms-transform: translate3d(5.25rem, -5.25rem, 0);
  transform: translate3d(5.25rem, -5.25rem, 0);
}

.loader>.group:nth-child(5) {
  -webkit-animation: spin 4.75s ease infinite;
  -moz-animation: spin 4.75s ease infinite;
  -o-animation: spin 4.75s ease infinite;
  -ms-animation: spin 4.75s ease infinite;
  animation: spin 4.75s ease infinite;
  -webkit-transform-origin: 0.25rem 0.25rem;
  -moz-transform-origin: 0.25rem 0.25rem;
  -o-transform-origin: 0.25rem 0.25rem;
  -ms-transform-origin: 0.25rem 0.25rem;
  transform-origin: 0.25rem 0.25rem;
}

.loader>.group:nth-child(5)>.dot {
  background-color: #252289;
}

.loader>.group:nth-child(5)>.dot:nth-child(1) {
  -webkit-transform: translate3d(6.25rem, 6.25rem, 0);
  -moz-transform: translate3d(6.25rem, 6.25rem, 0);
  -o-transform: translate3d(6.25rem, 6.25rem, 0);
  -ms-transform: translate3d(6.25rem, 6.25rem, 0);
  transform: translate3d(6.25rem, 6.25rem, 0);
}

.loader>.group:nth-child(5)>.dot:nth-child(2) {
  -webkit-transform: translate3d(-6.25rem, -6.25rem, 0);
  -moz-transform: translate3d(-6.25rem, -6.25rem, 0);
  -o-transform: translate3d(-6.25rem, -6.25rem, 0);
  -ms-transform: translate3d(-6.25rem, -6.25rem, 0);
  transform: translate3d(-6.25rem, -6.25rem, 0);
}

.loader>.group:nth-child(5)>.dot:nth-child(3) {
  -webkit-transform: translate3d(-6.25rem, 6.25rem, 0);
  -moz-transform: translate3d(-6.25rem, 6.25rem, 0);
  -o-transform: translate3d(-6.25rem, 6.25rem, 0);
  -ms-transform: translate3d(-6.25rem, 6.25rem, 0);
  transform: translate3d(-6.25rem, 6.25rem, 0);
}

.loader>.group:nth-child(5)>.dot:nth-child(4) {
  -webkit-transform: translate3d(6.25rem, -6.25rem, 0);
  -moz-transform: translate3d(6.25rem, -6.25rem, 0);
  -o-transform: translate3d(6.25rem, -6.25rem, 0);
  -ms-transform: translate3d(6.25rem, -6.25rem, 0);
  transform: translate3d(6.25rem, -6.25rem, 0);
}

.loader>.group:nth-child(6) {
  -webkit-animation: spin 5.5s ease infinite;
  -moz-animation: spin 5.5s ease infinite;
  -o-animation: spin 5.5s ease infinite;
  -ms-animation: spin 5.5s ease infinite;
  animation: spin 5.5s ease infinite;
  -webkit-transform-origin: 0.25rem 0.25rem;
  -moz-transform-origin: 0.25rem 0.25rem;
  -o-transform-origin: 0.25rem 0.25rem;
  -ms-transform-origin: 0.25rem 0.25rem;
  transform-origin: 0.25rem 0.25rem;
}

.loader>.group:nth-child(6)>.dot {
  background-color: #252289;
}

.loader>.group:nth-child(6)>.dot:nth-child(1) {
  -webkit-transform: translate3d(7.25rem, 7.25rem, 0);
  -moz-transform: translate3d(7.25rem, 7.25rem, 0);
  -o-transform: translate3d(7.25rem, 7.25rem, 0);
  -ms-transform: translate3d(7.25rem, 7.25rem, 0);
  transform: translate3d(7.25rem, 7.25rem, 0);
}

.loader>.group:nth-child(6)>.dot:nth-child(2) {
  -webkit-transform: translate3d(-7.25rem, -7.25rem, 0);
  -moz-transform: translate3d(-7.25rem, -7.25rem, 0);
  -o-transform: translate3d(-7.25rem, -7.25rem, 0);
  -ms-transform: translate3d(-7.25rem, -7.25rem, 0);
  transform: translate3d(-7.25rem, -7.25rem, 0);
}

.loader>.group:nth-child(6)>.dot:nth-child(3) {
  -webkit-transform: translate3d(-7.25rem, 7.25rem, 0);
  -moz-transform: translate3d(-7.25rem, 7.25rem, 0);
  -o-transform: translate3d(-7.25rem, 7.25rem, 0);
  -ms-transform: translate3d(-7.25rem, 7.25rem, 0);
  transform: translate3d(-7.25rem, 7.25rem, 0);
}

.loader>.group:nth-child(6)>.dot:nth-child(4) {
  -webkit-transform: translate3d(7.25rem, -7.25rem, 0);
  -moz-transform: translate3d(7.25rem, -7.25rem, 0);
  -o-transform: translate3d(7.25rem, -7.25rem, 0);
  -ms-transform: translate3d(7.25rem, -7.25rem, 0);
  transform: translate3d(7.25rem, -7.25rem, 0);
}

@-moz-keyframes spin {
  0% {
    -webkit-transform: rotateZ(0) scale(1);
    -moz-transform: rotateZ(0) scale(1);
    -o-transform: rotateZ(0) scale(1);
    -ms-transform: rotateZ(0) scale(1);
    transform: rotateZ(0) scale(1);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }

  50% {
    -webkit-transform: rotateZ(180deg) scale(2);
    -moz-transform: rotateZ(180deg) scale(2);
    -o-transform: rotateZ(180deg) scale(2);
    -ms-transform: rotateZ(180deg) scale(2);
    transform: rotateZ(180deg) scale(2);
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }

  100% {
    -webkit-transform: rotateZ(360deg) scale(1);
    -moz-transform: rotateZ(360deg) scale(1);
    -o-transform: rotateZ(360deg) scale(1);
    -ms-transform: rotateZ(360deg) scale(1);
    transform: rotateZ(360deg) scale(1);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotateZ(0) scale(1);
    -moz-transform: rotateZ(0) scale(1);
    -o-transform: rotateZ(0) scale(1);
    -ms-transform: rotateZ(0) scale(1);
    transform: rotateZ(0) scale(1);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }

  50% {
    -webkit-transform: rotateZ(180deg) scale(2);
    -moz-transform: rotateZ(180deg) scale(2);
    -o-transform: rotateZ(180deg) scale(2);
    -ms-transform: rotateZ(180deg) scale(2);
    transform: rotateZ(180deg) scale(2);
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }

  100% {
    -webkit-transform: rotateZ(360deg) scale(1);
    -moz-transform: rotateZ(360deg) scale(1);
    -o-transform: rotateZ(360deg) scale(1);
    -ms-transform: rotateZ(360deg) scale(1);
    transform: rotateZ(360deg) scale(1);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}

@-o-keyframes spin {
  0% {
    -webkit-transform: rotateZ(0) scale(1);
    -moz-transform: rotateZ(0) scale(1);
    -o-transform: rotateZ(0) scale(1);
    -ms-transform: rotateZ(0) scale(1);
    transform: rotateZ(0) scale(1);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }

  50% {
    -webkit-transform: rotateZ(180deg) scale(2);
    -moz-transform: rotateZ(180deg) scale(2);
    -o-transform: rotateZ(180deg) scale(2);
    -ms-transform: rotateZ(180deg) scale(2);
    transform: rotateZ(180deg) scale(2);
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }

  100% {
    -webkit-transform: rotateZ(360deg) scale(1);
    -moz-transform: rotateZ(360deg) scale(1);
    -o-transform: rotateZ(360deg) scale(1);
    -ms-transform: rotateZ(360deg) scale(1);
    transform: rotateZ(360deg) scale(1);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotateZ(0) scale(1);
    -moz-transform: rotateZ(0) scale(1);
    -o-transform: rotateZ(0) scale(1);
    -ms-transform: rotateZ(0) scale(1);
    transform: rotateZ(0) scale(1);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }

  50% {
    -webkit-transform: rotateZ(180deg) scale(2);
    -moz-transform: rotateZ(180deg) scale(2);
    -o-transform: rotateZ(180deg) scale(2);
    -ms-transform: rotateZ(180deg) scale(2);
    transform: rotateZ(180deg) scale(2);
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }

  100% {
    -webkit-transform: rotateZ(360deg) scale(1);
    -moz-transform: rotateZ(360deg) scale(1);
    -o-transform: rotateZ(360deg) scale(1);
    -ms-transform: rotateZ(360deg) scale(1);
    transform: rotateZ(360deg) scale(1);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}





@-webkit-keyframes moving-gradient {
  0% {
    background-position: -250px 0;
  }

  100% {
    background-position: 250px 0;
  }
}

.tableLoading {
  width: 100%;
  background: #fff;
  height: 850px;
  padding-top: 20px;
}

.tableLoading tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.tableLoading tr td {
  height: 50px;
  vertical-align: middle;
  padding: 8px;
}

.tableLoading tr td span {
  display: block;
}

.tableLoading tr td.td-1 {
  width: 20px;
}

.tableLoading tr td.td-1 span {
  width: 20px;
  height: 20px;
}

.tableLoading tr td.td-2 {
  width: 50px;
}

.tableLoading tr td.td-2 span {
  background-color: rgba(0, 0, 0, 0.15);
  width: 50px;
  height: 50px;
}

.tableLoading tr td.td-3 {
  width: 120px;
}

.tableLoading tr td.td-3 span {
  height: 12px;
  background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
  background-size: 500px 100px;
  animation-name: moving-gradient;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

.tableLoading tr td.td-5 {
  width: 60px;
  padding-right: 20px;
}

.tableLoading tr td.td-5 span {
  background-color: rgba(0, 0, 0, 0.15);
  width: 100%;
  height: 30px;
}

.InputWrapperRelative {
  position: relative;
}

.InputWrapperRelative .datePickerProfile {
  top: 38px;
  left: 3px;
}

.react-datetime-picker__inputGroup__input--hasLeadingZero {
  margin-left: 0 !important;
}

.loadingView {
  width: 100%;
  height: 100%;
  background-color: rgba(104, 104, 104, 0.5);
  display: block;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
}

.loaderView {
  position: fixed;
  z-index: 99999999999 !important;

}

.loadingView img {
  border-radius: 10%;

  width: 300px;
}

.vehicleThumb {
  width: 50px;
}

.nav-container {
  overflow: hidden !important;
}

.menuUlChild {
  width: 100%;
  padding-top: 10px;
}

.menuUlChild li {
  font-size: 15px;
  position: relative;
  font-weight: 500;
  color: #44566c;
}

.menuUlChild li::after {
  content: '';
  display: block;
  position: absolute;
  left: 6px;
  background: #44566c;
  border-radius: 100%;
  width: 7px;
  height: 7px;

}

.liDiv {
  flex: 1;
  display: flex;
  width: 100%;
  align-items: center;
}

.liParent {
  flex-direction: column;
  height: auto;
}

.menuLiIcon {
  opacity: 0.5;
}

.ActiveLi .menuLiIcon {
  opacity: 1 !important;
}

.filterTitle {
  color: #8697A8;
  font-weight: 600;
  padding: 15px;
  text-transform: uppercase;
}

.menuSectionTitle {
  background: #f8fafb;
  padding: 10px 20px;
  border-left: 4px solid #2eb0cf;
  margin-bottom: 20px;
  color: #44566c;
  font-size: 17px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.menuSectionTitle img {
  width: 25px;
  margin-right: 5px;
}

.childTitle {
  color: #8697a8;
  padding-left: 23px;
}

.menuChildIcon {
  width: 25px;
}

.side-items-second-element {
  position: relative;
}

.side-items-second-element span {
  position: absolute;
  right: 5px;
  top: 10px;
  border-radius: 15px;
  color: #fff;
  font-size: 13px;
  width: 35px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rbc-button-link {
  padding: 10px;
  font-size: 25px;
  font-weight: bold;
  color: #44566C;
}

.rbc-now .rbc-button-link {
  background: #44566C;
  color: #fff;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  padding: 5px;
  margin: 5px;
}

.rbc-show-more {
  font-size: 18px;
  padding: 5px;

  font-weight: 100;
}

.rbc-day-bg+.rbc-day-bg {
  border-left: 1px solid #EBEEF0;
}

.OPEN {
  background: #09B66D;
}

.CANCEL {
  background: #FF3D57;
}

.DISPATCHING {
  background: #FDBF5E;
}

.COMPLETE {
  background: #14123F;
}

.OPEN_WITH_SAFETY {
  background: #0fd3da;
}

.tableMapRow {
  cursor: pointer;
}

.mapPopUpView {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 99999999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mapPopUpViewClose {
  width: 60px;
  cursor: pointer;
  z-index: 99999999;
  height: 60px;
  position: fixed;
  top: 30px;
  right: 30px;
}

.mapPopUpViewClose img {
  width: 100%;
}

.MapContainerFleet {
  width: 100%;
  position: relative;
  display: flex;
  height: calc(100vh - 64px);

}

.marker-cluster div {
  display: flex;
  width: 80px;
  height: 80px;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 60px;
}

.EditCustomView {
  background-color: #fff;
  box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.1);
  padding: 28px;
  position: fixed;
  right: 0;
  top: 0;
  width: 800px;
  height: 100%;
  z-index: 99999;
  padding-bottom: 100px;
  overflow: scroll;
}

.EditCustomView .carImage {
  width: 130px;
  margin-bottom: 20px;
}

.EditCustomView .carImage {
  width: 130px;
  margin-bottom: 20px;
}

.inputBox {
  padding: 30px 10px;
  border-bottom: #EAEDF0 1px solid;
}

.inputBox .title {
  font-size: 20px;
  color: #727d88;
  font-weight: bold;
  margin-bottom: 10px;
  margin-left: -10px;
}

.EditContainer {
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  position: relative;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;

}

.mapOverview {
  width: 100%;
  height: 300px;
  background: red;
  margin-bottom: 20px;
}

.editUpdateDisabled {
  opacity: 0.4;
}

.rowhalf {
  display: flex;
  justify-content: space-between;
}


.rowhalf .editRow {
  width: 49%;

}

.rowhalf3 .editRow {
  width: 32%;

}

.colhalf {
  width: 49%;
  display: flex;
  flex-direction: column;
  justify-content: center;

}

.colhalf .carImage {
  width: 230px !important;
  margin-bottom: 0px;
}

.colhalf .editRow {
  width: 100%;
}

.inputBox .editRowTitle {
  color: #44566C;
  font-size: 13px;
}

.EditCustomView .row {
  color: #14123F !important;
}

.lockview {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;

}

.lockrow {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.lockrowTitle {
  width: 220px;
}

.lockrowBtns {
  display: flex;
  padding: 5px;
  justify-content: space-between;

  flex: 1;
}

.lockBtn {
  display: flex;
  padding: 10px;
  width: 48%;
  cursor: pointer;
  color: #fff;
  justify-content: center;
  align-items: center;
  border-radius: 5px;

}

.lockBtn img {
  margin-right: 5px;
}

.unlock {
  background: #09b66d;
  border-bottom: 3px solid #188d5c;

}

.lock {
  background: #FF3D57;
  border-bottom: 3px solid #c43b4d;
}

.editUpdate {
  padding: 10px 30px;
  background: #0081ff;
  margin: 14px;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  width: 150px;
  justify-content: center;
  align-items: center;
}

.disable {
  opacity: 0.3;
  cursor: auto;
}

.editViewFa {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(25, 25, 26, 0.93);
  left: 0;
  top: 0;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.editViewPopupDamage .image-picker-container {

  align-self: center;
  width: 100%;
  height: 220px;
  border-radius: 10px;
  background-color: #c3c3c3;
  box-shadow: 0 8px 16px 0 rgb(169 194 209 / 26%);
}

.editViewPopupDamage .image-picker-button-container {
  opacity: 1;
  z-index: 2;
  transition: 0.4s all;
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  background: rgba(68, 86, 108, 0.6);
  border-radius: 0%;
  justify-content: center;
  align-items: flex-end;
  display: flex;
  padding: 20px;
}

.editViewPopupDamage .picked-image-container {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 0%;
  overflow: hidden;
}

.editViewPopupDamage .updateUserBtn {
  width: 100%;
  margin-right: 0;
}

.picked-images {
  width: 100%;
}

.picked-images img {
  width: 50px;
  margin: 10px;
}

.editViewPopup {
  position: relative;
  width: 85vw;
  height: 90vh;
  display: flex;
  padding: 20px;
  border-radius: 10px;

}

.react-datetime-picker__clear-button {
  display: none;
}

.editViewPopupDamage {
  position: relative;
  width: 1050px;
  height: 850px;
  display: flex;
  padding: 20px;
  border-radius: 10px;
  overflow: visible;
}

.damegeItems {
  display: flex;
  flex-direction: column;
}

.damageList {
  background: #f8fafb;
  padding: 5px;
  border-radius: 3px;
  color: #14123F;
  flex-direction: column;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.damegeItem {
  width: 100%;
  cursor: pointer;
  background: #fff;
  border: #d1d1d1 1px solid;
  padding: 5px;
  border-radius: 3px;
  flex: 1;
  margin-bottom: 4px;
}

.damegeItem .description {
  font-weight: bold;
  font-size: 19px;
  margin-bottom: 2px;
}

.damegeItem .area {
  padding: 2px;
  border-radius: 3px;
  margin-right: 5px;
  background: #252289;
  color: #fff;
  font-size: 12px;
}

.damegeItem .bool {
  margin-top: 4px;
  margin-bottom: 4px;
}

.damegeItem .bool span {
  margin-right: 5px;
  box-shadow: #d1d1d1 0px 0px 10px 1px;
  padding: 2px;
  border-radius: 2px;
  font-size: 14px;
}

.is_valid-true,
.is_repaired-true {
  background: #09B66D;
  color: #fff;
}

.is_valid-false,
.is_repaired-false {
  background: #b21f2d;
  color: #fff;
}

.damegeItemImages img {
  width: 50px;
}


.billingHistoryFa {
  width: 100%;
  overflow-y: scroll;

}

.PaymentMethod {
  height: 38vh;
  width: 100%;
}

.checkbox-notification {
  display: flex;
  justify-content: space-around;
}

#editor {
  height: 250px;
  width: 100%;
  border: none;
  margin-top: 20px;
  background-color: #f8fafb;
  padding: 10px;
}

.messaging-title {
  margin-top: 20px;
  margin-bottom: -10px;
  color: #8697a8;
  font-size: 15px;
}

.userSelectedItemParent {
  display: flex;
  flex: 1;
  position: relative;
}

.usersLists {
  width: 400px;
  height: auto;
  max-height: 200px;
  background: #fff;
  box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.3);

  position: absolute;
  top: 40px;
  right: -400px;
  z-index: 999;
  padding: 15px;
  overflow-y: scroll;
}

.input-dynamic {
  min-width: auto;
}

.user-usersLists-selected {
  background: #007bffa2;

}

.userSelectedItem {
  background: #007bff;
  min-width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 3px;
  margin-right: 10px;
}

.input-with-label-flex {
  display: flex;
}

.user-usersLists {
  cursor: pointer;
  padding: 5px;
  border-bottom: 2px solid #eee;
}

.user-usersLists div {
  color: #44566C;
  font-size: 14px;
}

.h100 {
  height: 100% !important;
}

.checkbox-notification input {
  font-size: 50px;
  width: 40px;
  height: 40px;
}

.checkbox-with-title {
  margin-bottom: 10px;
}

.modal-account-account-detail {
  height: 100%;
  overflow: hidden;
}

.billingItem {
  display: flex;
  padding: 10px;
  align-items: center;
  position: relative;
  cursor: pointer;
  border-bottom: #8697A8 1px solid;
}

.billingItem img {
  width: 40px;
}

.billingItem .title {
  font-size: 17px;
  flex: 1;
  color: #44566C;
}

.billingDetails {
  display: flex;
  flex-direction: column;

}

.billingItem .price {
  color: #44566C;
}

.billingItem .invoice {
  color: #8697A8
}

.editViewClose {
  width: 30px;
  cursor: pointer;
  z-index: 9999;
  height: 30px;
  position: absolute;
  top: 40px;
  right: 40px;
}

.userInfoSide {
  background: #fff;
  display: flex;
  flex-direction: column;
  padding-right: 20px;
  border-right: #333 3px solid;

}

.userEditInfoSide {
  background: #fff;
  display: flex;
  flex: 1;
}

.userEditInfoSideCol {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 20px;

}

.cardItem {
  display: flex;
  flex-direction: column;
  padding: 5px;
  margin-top: 10px;
}

.cardItem span {
  color: rgb(123, 123, 123);
  margin-right: 10px;
}

.uploadedImg {
  width: auto;
  max-height: 100px;
  height: auto;
  border-radius: 3px;
}

.tableMapRowOpen {
  background: #ccc !important;
  height: 100%;
  width: 65%;
  overflow: hidden;
  bottom: 0;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
}

.tableMapContainer {
  display: flex;
  flex: 1;
}

.statusTag {
  width: 25px;
  height: 25px;
  display: flex;
  border-radius: 100%;
  margin-right: 5px;
}

.nameRow {
  display: flex;
  flex-direction: row;
}

.locationTablePoints span {
  padding: 3px;
  border-radius: 3px;
  background-color: #9b9b9b;
  color: #fff;
  font-size: 13px;
  margin-right: 5px;
}

.vehicleInfoTitle {
  margin-top: 15px;
  margin-bottom: 5px;
  font-weight: bold;
}

.vehicleInfoContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  background: #fff;
  padding: 10px;
  padding-top: 60px;
  height: 100%;
  width: 35%;
  overflow-y: scroll;
}

.vehicleInfo {
  padding: 20px;
  display: flex;
  background: #fff;

  border-radius: 10px 10px;
  z-index: 99999;
}

.carImgBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
}

.carImgBox div {
  padding-left: 5px;
  text-align: left;
  width: 100%;

}

.carImage {
  /* position: absolute;
  right: 50px;
  top:100px; */
  width: 200px;
  border-radius: 5px;
}

.carImageAbsoutle {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.replaceIcon {
  width: 100%;
  height: 50px;
  display: flex;
  position: absolute;
  top: -25px;
  justify-content: center;
  align-items: center;
}

.dispatcherImage {
  width: 100px;
  margin-right: 10px;
  border-radius: 5px;

}

.customerImage {
  width: 30px;
  margin-left: 10px;
  border-radius: 5px;

}

.dispatcherInfo {
  display: flex;
  color: #14123F;
}

.mapDispatching {
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 10px;
  min-width: 300px
}

.startDispatching {
  padding: 10px;
  background: #09b66d;
  border-bottom: 5px solid;
  border-color: #0b613d;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #fff;
  border-radius: 3px;
  margin-bottom: 10px;
}

.startDispatching span {
  font-size: 13px;
  margin-left: 10px;
}

.stopDispatching {
  background: #1d80fe !important;
  border-color: #0e57b6;

}

.canselDispatching {
  background: #b21f2d !important;
  border-color: #6d0c16;

}

.carInfo {
  padding: 10px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.carInfo img {
  border-radius: 10px;
  margin-right: 10px;
}

.carInfo .title {
  font-weight: bold;
  margin-bottom: 10px;
  color: #fff;
}

.carInfo .title img {
  background: #fff;
  padding: 2px;
  width: 80px;
}

.carInfo .row {
  margin-left: 0px;
  margin-bottom: 10px;
  font-weight: bold;
}

.leaflet-container {
  display: flex;
  height: 100%;
  margin-right: -50%;
}

.leaflet-container img {
  max-height: none;
}

.page-container {
  position: relative;
}

.tableTag {
  padding: 2px 5px;
  margin-left: 10px;
  border-radius: 5px;
  color: #fff;
}

.tableTagToday {
  background: #0081FF;

}

.tableTagTomaroow {
  background: #FDBF5E;

}

.tableTagPast {
  background: #2c2c2c;
}

.bookfor_ACTIVE {
  background: #09B66D !important;
}

.bookfor_CANCEL {
  background: #8697A8 !important;
}

.bookfor_COMPLETE {
  background: #000000 !important;
}

.datePickerSectionBookfor {
  position: relative;
}

.react-datetime-picker__inputGroup__input {
  padding: 0 !important;
}

.datePickerProfile {
  padding: 5px;
  background: #f8fafb;
  border-radius: 2px;
  opacity: 1;
  position: absolute;
  top: 33px;
  left: 0;
  width: 100%;

}

.react-datetime-picker {
  width: 100%;
  background: #f8fafb;
}

.react-datetime-picker__wrapper {
  border: none;
}

.errorRowPass {
  font-weight: bold;
  margin-top: 10px;
  list-style: circle;
}

.rowSpan {
  margin-left: 2px;
  /* background: #f8fafb; */
  padding: 5px;
  border-radius: 5px;
  /* box-shadow: #ccc 0 0 3px; */
}

.spanString {
  max-width: 250px;
  display: flex;
  white-space: break-spaces;
}

.imageSpace {
  width: 0px;
  height: 10px;
  display: flex;
  height: 60px;

}

.userImageTabele {
  margin-bottom: 10px;
}

.vehilceImageIconTable {
  position: absolute;
  left: 10px;
  width: 94px !important;
  max-height: 135px !important;
  top: 0;
  opacity: 1;
}

.num_bookings {
  padding: 10px;
  color: #fff;
  border-radius: 10px;
  background: #2c2c2c;

}

.num_bookings_green {
  background: #219e7f;
}

.table_span_NOT_SUBMITTED {
  background: #14123F;
  color: #fff;
}

.table_span_SUBMIT_AGAIN {
  background: #000000;
  color: #fff;
}

.table_span_NOT_VERIFIED {
  background: #FDBF5E;
  color: #fff;

}

.table_span_VERIFIED {
  background: #09B66D;
  color: #fff;

}

.table_span_DISABLED {
  background: #FA5A70;
  color: #fff;

}

.table_span_OPEN {
  background: #09B66D;
  color: #fff;

}

.table_span_OPEN_WITH_SAFETY {
  background: #0fd3da;
  color: #fff;

}

.table_span_CANCEL {
  background: #fa5a5a;
  color: #fff;

}

.table_span_CANCEL_NON_OPEN {
  background: #853636;
  color: #fff;

}

.table_span_COMPLETE {
  background: #14123F;
  color: #fff;
}

.table_span_ACTIVE {
  background: #007bff;
  color: #fff;
}

.table_span_DISPATCHING {
  background: #FDBF5E;
  color: #fff;

}

.statusParent {}

.table_span_status {
  display: flex;
  width: 180px;
  justify-content: center;
  align-content: center;
  border-radius: 3px;
}

.userTableCard {
  padding: 10px;
  border-radius: 5px;
  display: flex;
}

.userCardInfoFetch {
  border: #e9e9e9 2px solid;
}

.userCardInfo {
  display: flex;
}

.userCardVerified img {
  width: 18px !important;
  margin-left: 3px;
  margin-top: -5px;

}

.userCardfetch img {
  width: 18px !important;
  margin-left: 5px;
  margin-top: -5px;

}

.event-COMPLETE {
  background: #14123F !important;
}

.nextEvent {
  background: #FDC05E
}

.todayEvent {
  background: #0082FF
}

.event-ACTIVE {
  background: #09B66D;
}

.userTextArea .input-with-label {
  height: 220px;
}

.userTextArea .input-with-label textarea {
  padding: 15px;
  height: 100%;
}


.By_Hour {
  background: #db9958 !important;
}

.By_Minute {
  background: #63b6b6 !important;
}

.By_Day {
  background: #da516c !important;
}

.By_Hour-ACTIVE {
  background: repeating-linear-gradient(45deg,
      #db9958,
      #d69452 10px,
      #db9958 10px,
      #c58c53 20px) !important;
}

.By_Minute-ACTIVE {
  background: repeating-linear-gradient(45deg,
      #63b6b6,
      #58a7a7 10px,
      #509797 10px,
      #62bebe 20px) !important;
}

.By_Day-ACTIVE {
  background: repeating-linear-gradient(45deg,
      #ac3950,
      #bd425a 10px,
      #db677e 10px,
      #bd4e64 20px) !important;
}