* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
    font-family: Roboto;
    outline: none;
    font-family: Roboto;
}

.block {
    display: block !important;
}

body {
    background: #fff;
}

.Error {
    color: rgb(212, 71, 71);
}

.Success {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    color: #242b46;
    margin-top: 40px;
}

.Success strong {
    font-size: 22px;
    margin-bottom: 20px;
    font-weight: bold;
}

.resMassage {
    position: absolute;
    left: -150%;
    top: 99%;
}

menu {
    display: flex;
    padding: 20px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    background: #fff;
    z-index: 999999;
}

menu ul {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    margin-bottom: 0;
}

.MenuCenter {
    display: flex;
    flex: 0.3;
}

menu ul li {
    display: flex;
    height: 100%;
    margin: 0 4px;
}

menu ul li:last-child {
    border: none;
}

menu ul li a {
    display: flex;
    flex: 1;
    padding: 0 15px !important;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: #242b46;
}

.menu-mobile {
    display: none;
    position: absolute;
    right: 10px;
    width: 50px;
}

.menu-mobile img {
    width: 100%;
}

.headerLogo {
    width: 200px;
}

.homePage {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.SectionFa {
    width: 100%;
    background: #fff;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

#how {
    height: 20px;
}

.headerHome {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero-video {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.hero-video video {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    z-index: -100;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

.headerMain {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    /* background: linear-gradient(90deg, rgba(36, 43, 70, 0.7) 0%, rgba(0, 167, 206, 0.6) 100%); */
    color: #fff;
    font-size: 30px;
    font-weight: lighter;
    flex-direction: column;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    position: absolute;
    bottom: 0px;
    text-shadow: #000000 3px;
}

.headerButtons {
    display: flex;
    flex-direction: row;
}

.headerMain h1 {
    font-size: 88px;
    text-shadow: 0px 0px 6px black;


}
    .headerMain span {
    width: 80%;
    font-size: 38px;
    text-shadow: 0px 0px 6px black;
}

.headerMainSub {
    font-size: 28px;
    font-weight: bolder;
    margin-top: 10px;
}

.headerButton {
    font-size: 18px;
    padding: 20px;
    width: 250px;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
    background-color: #00a7ce;
    color: #fff;
    margin-left: 20px;
    cursor: pointer;
}

.ParallaxFA {
    width: 100%;
    display: flex;
    justify-content: center;
}

.Pitems {
    height: 500px;
}

.section {
    width: 950px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.itemCol {
    display: flex;
    justify-content: center;
    position: relative;
    height: 320px;
}

.itemColData .title {
    color: #242b46;
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 20px;
}

.itemColData {
    display: flex;
    flex-direction: column;
    padding: 0 60px;
    flex: 1;
}

.itemCol .imgBox {
    display: flex;
    flex: 1;
    justify-content: center;
    width: 50%;
}

.itemCol .imgBox img {
    height: 200px;
}

.itemColData .title {
    color: #242b46;
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 20px;
}

.itemColData .desc {
    color: #242b46;
    font-size: 18px;
}

.itemColInfo {
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.number {
    width: 30px;
    height: 30px;
    background: #fff;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #00a7ce;
    border: 2px solid #00a7ce;
}

.border {
    display: block;
    width: 2px;
    height: 100%;
    background: #00a7ce;
    margin-left: 14px;
}

.stay {
    width: 80%;
    height: 500px;
    padding: 50px 0;
    background: #f1f1f1;
    display: flex;
    justify-content: center;
    position: absolute;
    top: -320px;
}

.formStay {
    width: 250px;
    display: flex;
    flex-direction: column;
    text-align: center;
    position: relative;
    justify-content: center;
    align-items: center;
}

.formStay .title {
    color: #242b46;
    font-size: 40px;
}

.formStay .desc {
    color: #242b46;
    font-size: 17px;
    padding: 30px 0;
}

.formStay input {
    width: 120%;
    border-radius: 2px;
    border: #242b46 2px solid;
    height: 40px;
    margin-bottom: 20px;
    padding: 10px;
}

.formStay .formButton {
    background: #242b46;
    padding: 10px;
    cursor: pointer;
    color: #fff;
    width: 100%;
}

.response {
    display: flex;
    justify-content: center;
    align-items: center;
}
.goToApp {
    padding: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.goToApp a{
    padding: 10px;
    width: 250px;
    background: #242b46;
    text-align: center;
    color: #fff;
    border-radius: 3px;
}
.alertFetch {
    position: absolute;
    top: 40%;
    color: #242b46 !important;
    padding: 20px;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.alertFetch div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.alertFetch span {
    padding: 10px;
    font-size: 45px;
}

footer {
    background: #242b46;
    position: relative;
    display: flex;
    width: 100%;
    height: 350px;
    justify-content: center;
    align-items: center;
}

.footerInfo {
    width: 80%;
    position: absolute;
    left: 10%;
    bottom: 0;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}

.footerLinks {
    display: flex;
    flex-direction: column;
}

.footerInfo span {
    color: #fff;
}

.items {
    padding-top: 100px;
    margin-bottom: 270px;
}

.menuShow {
    display: block !important;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    position: absolute;
    left: 0;
    top: 96px;
    padding-bottom: 10px;
}

@media only screen and (max-width: 1350px) {
    .headerMain {
        /* margin-top: -180px; */
    }
}

@media only screen and (max-width: 960px) {
    .headerMain span {
        width: 100%;
    }

    .itemCol {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-flow: column-reverse;
        height: auto;
        margin-bottom: 30px;
    }

    .items {
        margin-bottom: 350px;
        padding: 0 30px;
        padding-top: 130px;
    }

    .headerMain {
        font-size: 25px;
        /* padding-left: 55px; */
    }

    .itemCol .imgBox,
    .itemCol .imgBox img {
        width: 80%;
        height: auto;
    }

    .imgBox img {
        margin-bottom: 30px;
    }

    menu ul {
        display: none;
    }

    .menu-mobile {
        display: block;
        top: 30px;
    }

    menu ul li a {
        justify-content: left;
        height: 75px;
        color: #00a7ce;
        font-size: 25px;
        font-weight: bold;
    }

    .headerMain {
        width: 90%;
        /* margin-left: 0.5%; */
    }

    footer {
        height: 380px;
    }

    .footerInfo {
        flex-direction: column;
    }

    .footerLinks {
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }

    .footerLinks span {
        margin-top: 10px;
    }

    .resMassage {
        position: static;
        margin-bottom: 20px;
    }

    .section {
        width: 100%;
    }

    .headerMain {
        /* margin-top: -320px; */
        height: auto;
        padding: 25px;
    }
}

@media only screen and (max-width: 770px) {}

@media only screen and (max-width: 560px) {}

.contactTC {
    padding: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1.9;
    margin-bottom: 200px;
    flex-direction: column;
}

.tabs {
    display: flex;
    margin-top: 10px;
    width: 100%;
    margin-bottom: 10px;
}

.tab {
    display: flex;
    flex: 1;
    text-align: center;
    justify-content: center;
    background-color: #e5e5e5;
    border-radius: 30px 30px 0 0;
    margin-right: 5px;
    padding: 20px;
    align-items: center;
    font-size: 22px;
    cursor: pointer;
}

.tabActive {
    background-image: linear-gradient(90deg, #0081ff, #00b7ff);
    color: #fff;
}

[data-custom-class="body"],
[data-custom-class="body"] * {
    background: transparent !important;
}

[data-custom-class="title"],
[data-custom-class="title"] * {
    font-family: Arial !important;
    font-size: 29px !important;
    color: #000000 !important;
}

[data-custom-class="subtitle"],
[data-custom-class="subtitle"] * {
    font-family: Arial !important;
    color: #595959 !important;
    font-size: 20px !important;
}

[data-custom-class="heading_1"],
[data-custom-class="heading_1"] * {
    font-family: Arial !important;
    font-size: 28px !important;
    color: #000000 !important;
}

[data-custom-class="heading_2"],
[data-custom-class="heading_2"] * {
    font-family: Arial !important;
    font-size: 22px !important;
    color: #000000 !important;
}

[data-custom-class="body_text"],
[data-custom-class="body_text"] * {
    color: #595959 !important;
    font-size: 18px !important;
    font-family: Arial !important;
}

[data-custom-class="link"],
[data-custom-class="link"] * {
    color: #3030f1 !important;
    font-size: 18px !important;
    font-family: Arial !important;
    word-break: break-word !important;
}

ul {
    list-style-type: square;
}

ul>li>ul {
    list-style-type: circle;
}

ul>li>ul>li>ul {
    list-style-type: square;
}

ol li {
    font-family: Arial;
}