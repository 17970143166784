.menuParent,
.full-section {
    background: #252289;
    border: none;
}

.menuParent {
    transition: 0.6s width;
}

.viewPanel::-webkit-scrollbar,
.nav::-webkit-scrollbar,
.itemViews::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    border-radius: 10px;
}

.page-container,
.content-container {
    background: #f4f5f7;
}

.content-container {
    padding-top: 0px;
    margin-top: 64px;
}

.tableContainer {
    padding-left: 8px;
    padding-top: 8px;
    box-shadow: none;
}

.liDiv small,
.menuUlChild li {
    color: #fff;
    min-width: 125px;
}

.links li small {
    white-space: pre;

}

.side-items-first-element img {
    width: 28px;
    height: 28px;
    aspect-ratio: 1/1;
    object-fit: contain;
}

.liParent .liDiv small {
    color: #fff;

}

.menuUlChild li::after {
    display: none;
}

.side-item-active-left {
    display: none;
}

.menuUlChild li {
    border-left: 3px solid #14133b;
    margin-bottom: 5px;
    list-style: none;
}

.ActiveLi {
    border-left: 3px solid #fff !important;

}


.side-items-first-element:hover {
    background: #191664;
}

.tableVehicle,
.search-input-table .input-with-icon,
.ulChildView,
.login-form-inputs-container {
    box-shadow: 0 2px 8px 0 rgb(169 194 209 / 60%);
}

.tableVehicle tbody tr:nth-child(odd) {
    /* background: rgba(248, 250, 251, 0.8); */
}

.main-container {
    flex-direction: column;
}

.header-drop-menu {
    position: absolute;
    right: 28px;
    top: 65px;
    width: 183px;
    height: 0px;
    transition: 0.4s height;
    overflow: hidden;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 3px 16px 0 rgb(206 221 230);
    z-index: 99999999999999;
}

.page-container {
    padding: 10px 20px;
}

.table-page-container .tableViewContainer {
    width: calc(100% + 45px);
}

.viewPanel {
    border-radius: 50px 0 0 50px;
    overflow-y: scroll;
    height: 100vh;
    background: #fff;
    z-index: 9999999;
}

.header-account-container {
    padding: 5px;
    border-radius: 60px;
    background: #fff;
    box-shadow: 0 2px 8px 0 rgb(169 194 209 / 60%);
}

.headericon {
    margin-right: 30px;
    background: #fff;
    box-shadow: 0 2px 8px 0 rgb(169 194 209 / 60%);
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-content: center;
    border-radius: 100%;
}

.headericon img {
    width: 20px;
}

.header-container {
    background: #fff;
    border-radius: 50px 0 0 0;
    border: none;
}

.header-drop-menu {
    width: 350px;
    height: 250px;
    padding: 15px;
}

.headerUesrRows {
    display: flex;
}

.headerUserItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    margin-top: 10px;
    padding: 5px;
    color: #44566c;
}

.headerUserItem img {
    margin-top: -4px;
}

.header-drop-menu button {
    text-align: center;
    background: #e3f0ff;
    display: flex;
    width: 100%;
    border-radius: 10px;
    margin-top: 20px;
    justify-content: center;
}

.headerUserItem .title {
    font-size: 12px;
}

.nav-container {
    border: none;
}

.menuLiIcon {
    opacity: 1;
}

.menuFilterView {
    padding-top: 0px;
    width: auto;
    margin-right: 310px;
    height: 50px;
    margin-bottom: -60px;
    margin-top: 40px;
    border-radius: 10px;
    align-self: end;
    position: relative;
    z-index: 9;
}

.search-input-table {
    width: 280px;
}

.search-input-table .input-with-icon {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}

.search-input-table .input-with-icon img {
    top: 13px;
}

.table-page-container .form-header {
    font-size: 30px;
}

.tableSearch {

    margin-top: -57px;
}

.filtersView {
    padding: 0px;
    padding-top: 0px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    border-radius: 10px;
    background: #fff;
}

.menuSectionTitle {
    margin-bottom: 0;
}


.filterItem {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    cursor: pointer;
    border-radius: 10px;

}

.filterItem:hover {
    background: #f0f8ff;
}

.filterItem span {
    border-radius: 15px;
    color: #fff;
    font-size: 13px;
    width: 35px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
}

.All-Count {
    background-color: #292929;

}

.filterItemActive {
    background: #daedfd;
}

.filterItem .menuChildIcon {
    margin-right: 10px;
    width: 25px;
}

.filtersView .links {
    display: flex;
    flex: 1;
}

.menuSectionTitle,
.filterTitle {
    display: none;
}

.menuViews {
    margin-top: 52px;
}

.tableMapRowOpen {
    border-radius: 0 60px 60px 0;
}

.main-auth-container {
    background: #fff;
}

.login-section {
    justify-content: center;
    align-content: center;
    display: flex;
    position: relative;

    flex: 1;
}

.login-shape {
    display: flex;
    width: 60%;
    /* background: url("/public/assets/backgrounds/ai.webp") center; */
    background: #fff;
    position: relative;
    height: 100vh;
    overflow: hidden;
    justify-content: center;
    align-items: center;
}



.login-shape .logo {

    width: 70%;
    position: absolute;
    z-index: 1;
}

.login-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.login-form-inputs-container {
    background: #fff;
    height: 500px;
    width: 400px;
    padding: 30px;
    border-radius: 10px;
}

.loadingView img {
    width: 180px;
}

.settingView {
    padding: 0 40px;
}

.StripeView {
    background: #fff;
    padding: 20px;

}
.StripeView div {    font-family: Roboto;
    font-size: 16px;
font-weight: 400;
    color: #44566c;

}
.stripe-select .row{
    width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}
.stripe-select .row input{
    border-radius: 10px;
}
.stripe-select .row .drop{
        width: 40%;
}
.StripeView .title {
    padding: 10px 0;
    display: flex;
    width: 100%;
    font-family: Roboto;
    font-size: 20px;
    font-weight: bolder;
    color: #44566c;
}

.tableSearchSetting {
    margin-top: 0;
}

.tableSearchSetting .search-input-table {
    width: 300px;
}

.tableSearch .search-input-table {
    margin-top: 2px;
}

td button {
    background: none;
    border: none;
    cursor: pointer;
}

td button img {
    width: 24px;
}

td input {
    border: none;
    padding: 5px;
    border-radius: 3px;
    background: rgb(239, 239, 239);
}

.ActiveLi .menuLiIcon {
    margin-left: -3px;
}

.vehiclesItems {
    display: flex;
}

.vehiclesItem {
    margin: 20px;
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 5px;
    padding: 20px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: #d1d1d1 0px 0px 10px 1px;

}

.vehiclesItem .img img {
    border-radius: 5px;
    width: 100%;
}

.ReportDamagesButton {
    width: 100%;
    display: flex;
    flex-flow: row-reverse;

}

.ReportDamagesButton button {
    margin: 10px;
    padding: 10px 30px;
    background: #0081ff;
    margin: 15px;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    min-width: 160px;
    height: 60px;
    border: none;
    box-shadow: #e9e9e9 0px 0px 10px 1px;

}