* {
  margin: 0;
  padding: 0;
}

@-webkit-keyframes autofill {
  to {
    color: #666;
    background: transparent;
  }
}

input:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}

a {
  cursor: pointer;
}

a:hover {
  text-decoration: none;
}

.main-container {
  /*height: 100vh;*/
}
.h100vh {
  height: 100vh;
}
.full-section {
  width: 100%;
  height: 100%;
}
.menuVersion{
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.menuVersion span{
  font-size: 9px;
  text-transform: capitalize;
  color: #9f9f9f;

}
.menuParent {
  position: fixed;
  height: 100vh;
  background: #fff;
  border-right: solid 1px #eaedf0;
}
.menuDesktop {
  width: 240px;
}
.menuMobile {
  width: 60px;
  
}

li {
  list-style-position: inside;
}

.deep-sky-blue-text {
  color: #0081ff;
}

input {
  outline: none;
  color: #44566c;
  font-family: Roboto;
}

.body-1-text {
  color: #44566c;
}

.body-2-text {
  color: #8697a8;
}

.deep-sky-blue-text {
  color: #0081ff;
}

.pale-grey-background {
  background-color: #f8fafb;
}

.text-404 {
  font-size: 100px;
  color: #1d80fe;
}

.not-found-desc {
  color: #34abf3;
  font-size: 35px;
  margin-left: 25px;
}

#root {
  overflow: hidden;
}

.modal {
  background-color: #7f7f7f94;
}
