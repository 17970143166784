.front-main-container {
  overflow-y: scroll;
}

.front-header-container {
  height: 90px;
  background-color: white;
  display: flex;
  align-items: center;
  padding: 0px 38px 0px 38px;
  z-index: 2;
  justify-content: space-between;
}

.front-footer-container {
  z-index: 2;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  padding: 60px 145px 30px 145px;
}

.front-footer-section-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-information-bottom {
  margin-top: 20px;
}

.footer-information-bottom small {
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: #737781;
}

.front-logo-header img {
  width: 215px;
  height: 40px;
  object-fit: contain;
}

.front-logo-footer img {
  width: 145px;
  height: 27px;
  object-fit: contain;
}

.front-logo-footer h3 {
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: #737781;
  width: 165px;
  margin-top: 15px;
}

.front-header-contents {
  display: flex;
  align-items: center;
}

.front-header-buttons {
  display: flex;
  align-items: center;
}

.front-header-links {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  padding-right: 20px;
}

.front-page-link-active {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0081ff;
  margin-right: 32px;
}

.front-page-link-deactive {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #44566c;
  margin-right: 32px;
}

.footer-page-link-deactive {
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: #737781;
  padding: 0px 10px 0px 10px;
}

.footer-page-link-deactive:hover {
  color: #0081ff;
}

.border-right {
  border-right: 1px solid #979898 !important;
}

.front-page-link-deactive:hover {
  color: #0081ff;
}

.front-header-buttons button {
  outline: none;
  padding: 0 10px;
  background-color: #0081ff;
  height: 40px;
  color: white;
  text-align: center;
  border-radius: 5px;
  box-shadow: unset;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  transition: 0.4s all;
  cursor: pointer;
  border: unset;
}

.front-header-buttons button:hover {
  box-shadow: 0 0px 15px 0 rgba(0, 129, 255, 0.2),
    0 -5px 15px 0 rgba(0, 129, 255, 0.15);
}

.front-header-buttons button.login {
  background-color: white;
  margin-right: 15px;
  color: #0081ff;
  border: 1px solid #0081ff;
}

.front-main-background-container {
  position: absolute;
  z-index: 1;
  width: 100%;
  top: 90px;
}

.front-main-background {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 730px;
  background-image: linear-gradient(to top, #003568, #0081ff);
}

.front-main-background img {
  width: 90%;
  height: 200px;
}

.front-main-background::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 0;
  height: 0;
  display: block;
  border-style: solid;
  border-width: 3vw 25vw;
  left: 0;
  border-color: transparent transparent #f8fafb #f8fafb;
}

.front-main-background::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 0;
  height: 0;
  display: block;
  border-style: solid;
  border-width: 3vw 25vw;
  right: 0;
  border-color: transparent #f8fafb #f8fafb transparent;
}

.front-main-container-overlay {
  padding: 110px 145px 0px 145px;
}

.front-main-container-overlay h1 {
  font-family: Roboto;
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  margin-bottom: 25px;
  color: white;
}

.front-main-container-overlay h2 {
  height: 25px;
  font-family: Roboto;
  font-size: 22px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: white;
}

.platoon-ready-section {
  height: 280px;
  background-image: linear-gradient(to right, #003568, #0081ff);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 200px 0px 200px;
}

.platoon-ready-section h2 {
  font-family: Roboto;
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: white;
  margin-bottom: 7px;
}

.platoon-ready-section h3 {
  font-family: Roboto;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: white;
}

.platoon-ready-section button {
  outline: none;
  padding: 0px;
  border: unset;
  border-radius: 5px;
  background-color: #0081ff;
  width: 250px;
  height: 50px;
  color: white;
  text-align: center;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  box-shadow: 0 0px 15px 0 rgba(45, 47, 49, 0.2),
    0 -5px 15px 0 rgba(0, 129, 255, 0.15);
  transition: 0.4s all;
  cursor: pointer;
}

.platoon-ready-section button:hover {
  box-shadow: 0 0px 15px 0 rgba(0, 129, 255, 0.2),
    0 -5px 15px 0 rgba(0, 129, 255, 0.15);
}

.front-landing-from {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.front-landing-from button {
  outline: none;
  padding: 0px;
  border: unset;
  border-radius: 5px;
  background-color: #0081ff;
  width: 250px;
  height: 50px;
  color: white;
  text-align: center;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  box-shadow: 0 0px 15px 0 rgba(45, 47, 49, 0.2),
    0 -5px 15px 0 rgba(0, 129, 255, 0.15);
  transition: 0.4s all;
  cursor: pointer;
}

.front-landing-from button:hover {
  box-shadow: 0 0px 15px 0 rgba(0, 129, 255, 0.2),
    0 -5px 15px 0 rgba(0, 129, 255, 0.15);
}

.front-landing-information-section {
  background-color: white;
  margin-top: 300px;
  margin-bottom: 40px;
  border-radius: 10px;
  height: 241px;
  display: flex;
  align-items: center;
  box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.1);
}

.front-landing-information-section div {
  padding: 0px 40px 0px 40px;
}

.front-landing-information-section div.line {
  width: 1px;
  height: 130px;
  padding: 24px;
  border-left: solid 1px #979797;
}

.front-landing-information-section p {
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: #737781;
  margin-top: 20px;
}

.front-landing-information-section small {
  font-family: Roboto;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #44566c;
}

.front-landing-information-section-individual {
  margin-bottom: 51px;
  display: flex;
  justify-content: space-between;
}

.front-landing-information-section-individual div {
  padding: 20px 50px 20px 50px;
  background-color: white;
  border-radius: 10px;
  height: 148px;
  width: 560px;
  box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.1);
}

.front-landing-information-section-individual p {
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: #737781;
  margin-top: 20px;
}

.front-landing-information-section-individual small {
  font-family: Roboto;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #44566c;
}

.front-header-buttons button:hover {
  box-shadow: 0 0px 15px 0 rgba(0, 129, 255, 0.2),
    0 -5px 15px 0 rgba(0, 129, 255, 0.15);
}

.front-header-buttons button.login {
  outline: none;
  padding: 0px;
  background-color: white;
  width: 110px;
  height: 40px;
  color: #0081ff;
  border: 1px solid #0081ff;
  text-align: center;
}

.front-main-background-container {
  position: absolute;
  z-index: 1;
  width: 100%;
  top: 90px;
}

.front-main-background {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 730px;
  background-image: linear-gradient(to top, #003568, #0081ff);
}

.front-main-background img {
  width: 90%;
  height: 200px;
}

.front-main-background::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 0;
  height: 0;
  display: block;
  border-style: solid;
  border-width: 3vw 25vw;
  left: 0;
  border-color: transparent transparent #f8fafb #f8fafb;
}

.front-main-background::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 0;
  height: 0;
  display: block;
  border-style: solid;
  border-width: 3vw 25vw;
  right: 0;
  border-color: transparent #f8fafb #f8fafb transparent;
}

.front-main-container-overlay {
  padding: 110px 145px 0px 145px;
}

.front-main-container-overlay h1 {
  font-family: Roboto;
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  margin-bottom: 25px;
  color: white;
}

.front-main-container-overlay h2 {
  height: 25px;
  font-family: Roboto;
  font-size: 22px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: white;
}

.platoon-ready-section {
  height: 280px;
  background-image: linear-gradient(to right, #003568, #0081ff);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 200px 0px 200px;
}

.platoon-ready-section h2 {
  font-family: Roboto;
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: white;
  margin-bottom: 7px;
}

.platoon-ready-section h3 {
  font-family: Roboto;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: white;
}

.platoon-ready-section button {
  outline: none;
  padding: 0px;
  border: unset;
  border-radius: 5px;
  background-color: #0081ff;
  width: 250px;
  height: 50px;
  color: white;
  text-align: center;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  box-shadow: 0 0px 15px 0 rgba(45, 47, 49, 0.2),
    0 -5px 15px 0 rgba(0, 129, 255, 0.15);
  transition: 0.4s all;
  cursor: pointer;
}

.platoon-ready-section button:hover {
  box-shadow: 0 0px 15px 0 rgba(0, 129, 255, 0.2),
    0 -5px 15px 0 rgba(0, 129, 255, 0.15);
}

.front-landing-from {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.front-landing-from button {
  outline: none;
  padding: 0px;
  border: unset;
  border-radius: 5px;
  background-color: #0081ff;
  width: 250px;
  height: 50px;
  color: white;
  text-align: center;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  box-shadow: 0 0px 15px 0 rgba(45, 47, 49, 0.2),
    0 -5px 15px 0 rgba(0, 129, 255, 0.15);
  transition: 0.4s all;
  cursor: pointer;
}

.front-landing-from button:hover {
  box-shadow: 0 0px 15px 0 rgba(0, 129, 255, 0.2),
    0 -5px 15px 0 rgba(0, 129, 255, 0.15);
}

.front-landing-information-section {
  background-color: white;
  margin-top: 300px;
  margin-bottom: 40px;
  border-radius: 10px;
  padding-top: 25px;
  height: 241px;
  display: flex;
  box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.1);
}

.front-landing-information-section div {
  padding: 0px 60px 0px 60px;
}

.front-landing-information-section div.line {
  width: 1px;
  height: 130px;
  padding: 24px;
  border-left: solid 1px #979797;
}

.front-landing-information-section p {
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: #737781;
  margin-top: 20px;
}

.front-landing-information-section small {
  font-family: Roboto;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #44566c;
}

.front-landing-information-section-individual {
  margin-bottom: 51px;
  display: flex;
  justify-content: space-between;
}

.front-landing-information-section-individual div {
  padding: 20px 50px 20px 50px;
  background-color: white;
  border-radius: 10px;
  height: 148px;
  width: 560px;
  box-shadow: 0 8px 16px 0 rgba(169, 194, 209, 0.1);
}

.front-landing-information-section-individual p {
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: #737781;
  margin-top: 20px;
}

.front-landing-information-section-individual small {
  font-family: Roboto;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #44566c;
}
